import React from 'react';
import { Col, Avatar, Progress, Row, Typography } from 'antd';
import { GoalItem } from '@models/GoalItem';
import {
  calculateEquivalentPercentage,
  calculateEquivalentPercentageNumber,
  formatCurrency,
} from '@utils/currency-format';
import moment from 'moment';

const { Title, Text } = Typography;
interface RepresentativeCardHeaderProps {
  goalItem: GoalItem;
}
const RepresentativeCardHeader: React.FC<RepresentativeCardHeaderProps> = ({ goalItem }) => {
  const calculatePercentage = (
    reachedValue: number,
    goalValue: number,
    reachedQuantity: number,
    goalQuantity: number,
  ): string => {
    if (reachedValue && goalValue) return ` (${calculateEquivalentPercentage(goalValue, reachedValue)})`;

    if (reachedQuantity && goalQuantity) return ` (${calculateEquivalentPercentage(goalQuantity, reachedQuantity)})`;

    return ' (0%)';
  };

  return (
    <Row className="w-100" gutter={[16, 16]}>
      <Col className="d-flex gutter-row align-center" xs={24} sm={24} md={24} lg={12} xl={8} xxl={6}>
        <Row gutter={[16, 16]}>
          <Col className="gutter-row d-flex align-center justify-center">
            <Avatar
              style={{ backgroundColor: 'var(--primary-color)', verticalAlign: 'middle' }}
              size="large"
              src={goalItem?.representative?.photoUrl}
            >
              {goalItem?.representative?.name[0]}
            </Avatar>
          </Col>
          <Col className="gutter-row">
            <Title level={5} className="mb-1">
              Representante
            </Title>
            <Text>
              #{goalItem?.representative?.code} | {goalItem?.representative?.name}
            </Text>
          </Col>
        </Row>
      </Col>

      <Col className="gutter-row" xs={24} sm={24} md={24} lg={6} xl={6} xxl={3}>
        <Title level={5} className="mb-1">
          Prazo
        </Title>
        <Text>
          {moment(goalItem.initialDate).format('DD/MM/YYYY')} a {moment(goalItem.finalDate).format('DD/MM/YYYY')}
        </Text>
      </Col>

      <Col className="gutter-row d-flex flex-column bg-danger" xs={24} sm={24} md={24} lg={6} xl={5} xxl={3}>
        <Title level={5}>Realizado</Title>
        {goalItem?.reachedValue && (
          <Text>
            {formatCurrency(goalItem?.reachedValue) ?? formatCurrency(Number(0))}
            {calculatePercentage(
              goalItem?.reachedValue,
              goalItem?.goalValue,
              goalItem?.reachedQuantity,
              goalItem?.goalQuantity,
            )}
          </Text>
        )}
        {goalItem?.reachedQuantity && (
          <Text>
            Qtd: {formatCurrency(goalItem?.reachedQuantity) ?? formatCurrency(Number(0))}
            {calculatePercentage(
              goalItem?.reachedValue,
              goalItem?.goalValue,
              goalItem?.reachedQuantity,
              goalItem?.goalQuantity,
            )}
          </Text>
        )}
      </Col>

      <Col className="gutter-row d-flex flex-column" xs={24} sm={24} md={24} lg={6} xl={5} xxl={3}>
        <Title level={5}>Meta</Title>
        {goalItem?.goalValue && <Text>{formatCurrency(goalItem?.goalValue) ?? formatCurrency(Number(0))}</Text>}
        {goalItem?.goalQuantity && <Text>Qtd: {goalItem?.goalQuantity?.toFixed(2) ?? Number(0).toFixed(2)}</Text>}
      </Col>

      <Col className="gutter-row" xs={24} sm={24} md={24} lg={18} xl={24} xxl={6}>
        <Progress
          className="w-100"
          strokeColor={{
            '0%': '#eb0045',
            '100%': '#510c76',
          }}
          percent={calculateEquivalentPercentageNumber(
            goalItem?.goalValue ? goalItem?.goalValue : goalItem?.goalQuantity,
            goalItem?.reachedValue ? goalItem?.reachedValue : goalItem?.reachedQuantity,
          )}
        />
      </Col>
    </Row>
  );
};

export default RepresentativeCardHeader;
