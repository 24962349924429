import { Response } from '@models/Response';
import * as api from './api';

export function getByCode(operationalAreaCode: number): Promise<any> {
  return api.get(`operational_areas/${operationalAreaCode}`);
}

export function getCustomers(params: any): Promise<any> {
  return api.post(`legalperson/search`, params);
}

export function getByBrandId(brandId: number, params?: any) {
  return api.get(`operational_areas/brand/${brandId}`, params);
}
