import { PaymentConditions } from '@models/PaymentConditions';
import { PaymentsInstallents } from '@models/PaymentInstallments';

import * as api from './api';

export async function get(params?: any) {
  return api.get('paymentConditions', params);
}

export async function update(paymentConditions: PaymentConditions, code: number): Promise<any> {
  return api.put(`paymentConditions/${code}`, paymentConditions).catch((error) => {
    console.log('no update', error, error.response);
    return Promise.resolve(error.response);
  });
}

export async function patchMaxInstallment(maxInstallmentDto: PaymentsInstallents) {
  return await api.post(`official-store/${maxInstallmentDto.officialStoreId}/installment`, maxInstallmentDto);
}
