import * as api from './api';

export function uploadImage(file: any): Promise<any> {
  const config = {
    headers: {
      'content-type': 'multipart/form-data',
    },
  };
  const formData = new FormData();
  formData.append('file', file);
  return api.postWithConfig('upload/images', formData, config);
}

type PhotoProps = {
  ReferenceCode: string;
  code: string;
  sequence: string;
  image: string;
  image_medium?: string;
  image_small?: string;
};
export async function saveImageColorReferences(body: PhotoProps): Promise<any> {
  return api.post('imageColorReferences', body)
}

export async function deleteImageColorReferences(ReferenceCode: string, code: string, sequence: string): Promise<any> {
  return api.remove(`imageColorReferences/${ReferenceCode}/${code}/${sequence}`);
}
