import { notification } from 'antd';
import React, { createContext, ReactNode, useContext, useState } from 'react';

import * as ImageService from '@services/images';
import { ImageProps } from '@models/ImageProps';

interface ImagesState {
  loading: boolean;
  fileList: ImageProps[];
  updateFileList: Function;
  removeImage: Function;
  uploadImages: Function;
  clearImages: Function;
}

interface ImagesProviderProps {
  children: ReactNode;
}

export const ImagesContext = createContext<ImagesState>({} as ImagesState);
const ImagesProvider: React.FC<ImagesProviderProps> = ({ children }) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [fileList, setFileList] = useState<ImageProps[]>([]);

  const updateFileList = ({
    file,
    url,
    reference,
    color,
    sequence,
  }: {
    file: File;
    url: string;
    reference: string;
    color: string;
    sequence: string;
  }) => {
    setFileList((current) => [...current, { name: file.name, url, reference, color, sequence, file }]);
  };

  const removeImage = (img: ImageProps) => {
    const index = fileList.findIndex(
      (file) => file.reference === img.reference && file.color === img.color && file.sequence === img.sequence,
    );
    if (index !== -1) {
      fileList.splice(index, 1);
      setFileList([...fileList]);
    }
  };

  const uploadImages = async () => {
    setLoading(true);
    let error: boolean = false;

    for (const [i, file] of fileList.entries()) {
      try {
        const { data } = await ImageService.uploadImage(file.file);

        await ImageService.saveImageColorReferences({
          image: data.large,
          image_medium: data.medium,
          image_small: data.small,
          ReferenceCode: file.reference,
          code: file.color,
          sequence: file.sequence,
        });
      } catch(e) {
        error = true;
      }
      fileList[i] = { ...fileList[i], uploaded: true, error };
      setFileList([...fileList]);
    }

    setLoading(false);

    if (fileList.filter((file) => file.error).length) {
      notification.warning({
        message: 'Aviso!',
        description: 'Algumas imagens não foram enviadas corretamente.',
      });
    } else {
      notification.success({
        message: 'Sucesso!',
        description: 'As imagens foram salvas com sucesso.',
      });

      await new Promise((resolve) => setTimeout(() => resolve(0), 1000));
      setFileList([]);
    }
  };

  const clearImages = () => {
    setFileList([]);
  };

  return (
    <ImagesContext.Provider
      value={{
        loading,
        fileList,
        updateFileList,
        removeImage,
        uploadImages,
        clearImages,
      }}
    >
      {children}
    </ImagesContext.Provider>
  );
};

const useImages = () => {
  return useContext(ImagesContext);
};

export { ImagesProvider, useImages };
