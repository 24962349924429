import { useOrder } from '@hooks/OrdersContext';
import { Table } from 'antd';
import React, { useEffect } from 'react';

import { columns } from '../../columns';
import OrderItemsTabs from '../OrderItemsTabs';

const AttendedTab: React.FC = () => {
  const { attendedOrders, loadAttendedOrders, loadingAttendedOrders, attendedFilters, attendedPage, attendedTotal } =
    useOrder();

  useEffect(() => {
    loadAttendedOrders(0);
  }, []);

  const handleTableChange = (pagination: any, filters: any, sorter: any) => {
    if (sorter.hasOwnProperty('column')) {
      attendedFilters.field = `${sorter.field}`;
      attendedFilters.order = `${sorter.order}`;
    }

    loadAttendedOrders(pagination.current, attendedFilters);
  };

  return (
    <Table
      columns={columns}
      dataSource={attendedOrders}
      loading={loadingAttendedOrders}
      expandable={{
        expandedRowRender: (record) => <OrderItemsTabs order={record} />,
      }}
      pagination={{
        pageSize: 10,
        total: attendedTotal,
        current: attendedPage,
        showSizeChanger: false,
        showQuickJumper: false,
      }}
      scroll={{ x: 800 }}
      rowKey={(record) => record.orderCode}
      onChange={handleTableChange}
    />
  );
};

export default AttendedTab;
