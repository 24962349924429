import React, { useCallback, useEffect, useState } from 'react';
import { Redirect, Route, Switch, useRouteMatch, useHistory } from 'react-router-dom';

import { Layout, Menu } from 'antd';
import {
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  ShoppingOutlined,
  SkinOutlined,
  AppstoreOutlined,
  ProfileOutlined,
  SettingOutlined,
  ShoppingCartOutlined,
  TeamOutlined,
  UserOutlined,
  ScheduleOutlined,
} from '@ant-design/icons';

import ModaVestBrand from '@assets/svg/modavest-brand.svg';
import ModavestBrandLogo from '@assets/png/brand-modavest.png';
import { ReactComponent as ModaVestIcon } from '@assets/icons/modavest.svg';
import { ReactComponent as LogoutIcon } from '@assets/icons/logout.svg';

import { useKeycloak } from '@react-keycloak/web';
import { KeycloakProfile } from 'keycloak-js';

import './styles.css';
import { Container, Header, Content, Footer } from './styles';
import CategoryPage from '@pages/CategoryPage';
import CollectionPage from '@pages/CollectionPage';
import GoalsPage from '@pages/GoalsPage';
import OfficialStorePage from '@pages/OfficialStorePage';
import OrderPage from '@pages/OrderPage';
import BrandsPage from '@pages/BrandsPage';
import SellersPage from '@pages/SellersPage';
import PriceTablesPage from '@pages/PriceTablesPage';
import ProductsPage from '@pages/ProductsPage';
import UploadImagesPage from '@pages/UploadImagesPage';
import RepresentativesPage from '@pages/RepresentativesPage';
import SalesRegionPage from '@pages/SalesRegionPage';
import PaymentPage from '@pages/PaymentPage';
import CollaboratorsPage from '@pages/CollaboratorsPage';
import OfficialStoreProfilePage from '@pages/OfficialStoreProfilePage';
import OfficialStoreNewPage from '@pages/OfficialStoreNewPage';
import ManufacturerPage from '@pages/ManufacturerPage';
import CollectionDetailsPage from '@pages/CollectionDetailsPage';
import OfficialStoreMenuPage from '@pages/OfficialStoreMenuPage';
import OfficialStoreOrderClassificationsPage from '@pages/OfficialStoreOrderClassificationsPage';
import OfficialStoreCollectionsPage from '@pages/OfficialStoreCollectionsPage';
import OfficialStorePaymentPage from '@pages/OfficialStorePaymentPage';
import OfficialStoreQuestionsPage from '@pages/OfficialStoreQuestionsPage';
import { useManufacturer } from '@hooks/ManufacturerContext';
import RepresentativesProfilePage from '@pages/RepresentativeProfilePage';
import OfficialStoreRepresentativePage from '@pages/OfficialStoreRepresentativePage';
import CustomersPage from '@pages/CustomersPage';
import CustomerProfilePage from '@pages/CustomerProfilePage';
import ParametersPage from '@pages/ParametersPage';
import SellersProfilePage from '@pages/SellersProfilePage';
import GoalDetailsPage from '@pages/GoalDetailsPage';
import VisitsPage from '@pages/VisitsPage';
import OfficialStoreSellerPage from '@pages/OfficialStoreSellerPage';

const { Sider } = Layout;
const { SubMenu } = Menu;

interface KeycloakJwtTokenParsed extends Keycloak.KeycloakTokenParsed {
  integration_id: number;
}

interface IntegrationAdditionalFieldsProps {
  additionalFieldsPhotoUrl: string;
}

const MainPage: React.FC = () => {
  const [user, setUser] = useState<KeycloakProfile>();
  const { path, url } = useRouteMatch();
  const { keycloak } = useKeycloak();
  const history = useHistory();
  const { fields, loadRequestFields } = useManufacturer();
  const [collapsed, setCollapsed] = useState(false);
  const [isCollaborator, setIsCollaborator] = useState<boolean>(false);

  let { additionalFieldsPhotoUrl }: IntegrationAdditionalFieldsProps = fields;

  const handleSignout = useCallback(() => {
    keycloak?.logout();
  }, []);

  const openUrl = useCallback(
    (_path: string) => {
      history.push(_path);
    },
    [history],
  );

  useEffect(() => {
    if (keycloak) {
      const allowedRoles = ['ans', 'collaborator', 'manufacturer'];
      let hasPermission = false;

      allowedRoles.forEach((role) => {
        if (keycloak.hasRealmRole(role)) hasPermission = true;
      });

      if (!hasPermission) {
        handleSignout();
      }

      setIsCollaborator(keycloak.hasRealmRole('collaborator'));
      if (keycloak?.authenticated) {
        keycloak.loadUserProfile().then((profile: KeycloakProfile) => {
          setUser(profile);
        });
        const { integration_id } = keycloak.tokenParsed as KeycloakJwtTokenParsed;
        if (integration_id) localStorage.setItem('@modavest/integration_id', integration_id.toString());
      }
    }
  }, [keycloak]);

  useEffect(() => {
    loadRequestFields();
  }, []);

  const verifyIsCollaboratorBlockedRoute = (_path: string, Component: React.FC) => {
    if (!isCollaborator) {
      return (
        <Route exact path={`${_path}`}>
          <Component />
        </Route>
      );
    }
  };

  return (
    <Container>
      <Sider style={{ overflowY: 'auto' }} collapsed={collapsed} theme="dark">
        {collapsed ? (
          <ModaVestIcon className="ml-3 my-4" width="40" height="40" />
        ) : (
          <>
            <img className="ml-6 my-4" src={ModaVestBrand} title="ModaVest" alt="logotipo-modavest" />
            <div className="text-center">
              <h3 className="menu-color">{user?.firstName}</h3>
            </div>
          </>
        )}
        <Menu theme="dark" mode="inline">
          {!isCollaborator && (
            <Menu.Item onClick={() => openUrl(`${url}/categorias`)} key="1" icon={<AppstoreOutlined />}>
              Categorias
            </Menu.Item>
          )}

          <Menu.Item onClick={() => openUrl(`${url}/coleções`)} key="2" icon={<SkinOutlined />}>
            Coleções
          </Menu.Item>

          <Menu.Item onClick={() => openUrl(`${url}/lojistas`)} key="lojistas" icon={<UserOutlined />}>
            Lojistas
          </Menu.Item>

          <Menu.Item onClick={() => openUrl(`${url}/metas`)} key="metas" icon={<ScheduleOutlined />}>
            Metas
          </Menu.Item>

          <Menu.Item onClick={() => openUrl(`${url}/lojas-oficiais`)} key="3" icon={<ShoppingOutlined />}>
            Lojas Oficiais
          </Menu.Item>

          {!isCollaborator && (
            <Menu.Item onClick={() => openUrl(`${url}/minhas-marcas`)} key="4" icon={<ProfileOutlined />}>
              Minhas Marcas
            </Menu.Item>
          )}

          <Menu.Item onClick={() => openUrl(`${url}/pedidos`)} key="5" icon={<ShoppingCartOutlined />}>
            Pedidos
          </Menu.Item>
          <Menu.Item onClick={() => openUrl(`${url}/vendedores`)} key="6" icon={<TeamOutlined />}>
            Vendedores
          </Menu.Item>

          <>
            <SubMenu title="Produtos" icon={<ShoppingOutlined />} key="7">
              <Menu.Item onClick={() => openUrl(`${url}/tabela-preco`)}>Tabela de Preço</Menu.Item>
              <Menu.Item onClick={() => openUrl(`${url}/meus-produtos`)}>Meus Produtos</Menu.Item>
              <Menu.Item onClick={() => openUrl(`${url}/carregar-imagens`)}>Carregar Imagens</Menu.Item>
            </SubMenu>
            <SubMenu title="Representantes" key="8" icon={<TeamOutlined />}>
              <Menu.Item onClick={() => openUrl(`${url}/meus-representantes`)}>Meus Representantes</Menu.Item>
              <Menu.Item onClick={() => openUrl(`${url}/região-de-venda`)}>Região de venda</Menu.Item>
              <Menu.Item onClick={() => openUrl(`${url}/visitas`)}>Visitas</Menu.Item>
            </SubMenu>
          </>
          {!isCollaborator && (
            <>
              <SubMenu title="Configurações" key="10" icon={<SettingOutlined />}>
                <Menu.Item onClick={() => openUrl(`${url}/pagamento`)}>Pagamento</Menu.Item>
                <Menu.Item onClick={() => openUrl(`${url}/meus-colaboradores`)}>Meus Colaboradores</Menu.Item>
                <Menu.Item onClick={() => openUrl(`${url}/fabricantes`)} key="13">
                  Perfil de Fabricante
                </Menu.Item>
                <Menu.Item onClick={() => openUrl(`${url}/parametros`)}>Parâmetros</Menu.Item>
              </SubMenu>
            </>
          )}

          <Menu.Item
            key="14"
            className="mt-2 w-100 d-flex align-center pl-6 pr-0"
            onClick={handleSignout}
            icon={<LogoutIcon width="22" height="22" fill="white" />}
          >
            Sair
          </Menu.Item>
        </Menu>
      </Sider>
      <Layout>
        <Header className="d-flex justify-space-between align-center">
          {React.createElement(collapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {
            className: 'trigger',
            onClick: () => {
              setCollapsed(!collapsed);
            },
          })}
          <img src={additionalFieldsPhotoUrl} width={80} alt="" />
          <img src={ModavestBrandLogo} width={200} />
        </Header>
        <Content style={{ backgroundColor: '#f5f5f5' }} className="py-4 px-8 overflow-auto">
          <Switch>
            {verifyIsCollaboratorBlockedRoute(`${path}/categorias`, CategoryPage)}
            {verifyIsCollaboratorBlockedRoute(`${path}/pagamento`, PaymentPage)}
            {verifyIsCollaboratorBlockedRoute(`${path}/meus-colaboradores`, CollaboratorsPage)}
            {verifyIsCollaboratorBlockedRoute(`${path}/fabricantes`, ManufacturerPage)}
            {verifyIsCollaboratorBlockedRoute(`${path}/parametros`, ParametersPage)}
            {verifyIsCollaboratorBlockedRoute(`${path}/minhas-marcas`, BrandsPage)}

            <Route exact path={`${path}/vendedores`}>
              <SellersPage />
            </Route>
            <Route exact path={`${path}/vendedores/perfil/:id`}>
              <SellersProfilePage />
            </Route>
            <Route exact path={`${path}/tabela-preco`}>
              <PriceTablesPage />
            </Route>
            <Route exact path={`${path}/meus-produtos`}>
              <ProductsPage />
            </Route>
            <Route exact path={`${path}/carregar-imagens`}>
              <UploadImagesPage />
            </Route>
            <Route exact path={`${path}/meus-representantes`}>
              <RepresentativesPage />
            </Route>
            <Route exact path={`${path}/meus-representantes/perfil/:id`}>
              <RepresentativesProfilePage />
            </Route>
            <Route exact path={`${path}/região-de-venda`}>
              <SalesRegionPage />
            </Route>
            <Route exact path={`${path}/visitas`}>
              <VisitsPage />
            </Route>
            <Route exact path={`${path}/coleções`}>
              <CollectionPage />
            </Route>
            <Route exact path={`${path}/coleções/detalhes/:id`}>
              <CollectionDetailsPage />
            </Route>
            <Route exact path={`${path}/lojistas`}>
              <CustomersPage />
            </Route>
            <Route exact path={`${path}/lojistas/perfil/:id`}>
              <CustomerProfilePage />
            </Route>
            <Route exact path={`${path}/lojas-oficiais`}>
              <OfficialStorePage />
            </Route>
            <Route exact path={`${path}/lojas-oficiais/nova-loja`}>
              <OfficialStoreNewPage />
            </Route>
            <Route exact path={`${path}/lojas-oficiais/perfil/:id`}>
              <OfficialStoreProfilePage />
            </Route>
            <Route exact path={`${path}/lojas-oficiais/perfil/:id/colecoes`}>
              <OfficialStoreCollectionsPage />
            </Route>
            <Route exact path={`${path}/lojas-oficiais/perfil/:id/menu`}>
              <OfficialStoreMenuPage />
            </Route>
            <Route exact path={`${path}/lojas-oficiais/perfil/:id/pedidos/classificacoes`}>
              <OfficialStoreOrderClassificationsPage />
            </Route>
            <Route exact path={`${path}/lojas-oficiais/perfil/:id/pagamento`}>
              <OfficialStorePaymentPage />
            </Route>
            <Route exact path={`${path}/lojas-oficiais/perfil/:id/representantes`}>
              <OfficialStoreRepresentativePage />
            </Route>
            <Route exact path={`${path}/lojas-oficiais/perfil/:id/vendedores`}>
              <OfficialStoreSellerPage />
            </Route>
            <Route exact path={`${path}/lojas-oficiais/perfil/:id/perguntas`}>
              <OfficialStoreQuestionsPage />
            </Route>
            <Route exact path={`${path}/pedidos`}>
              <OrderPage />
            </Route>
            <Route exact path={`${path}/metas`}>
              <GoalsPage />
            </Route>
            <Route exact path={`${path}/metas/:id`}>
              <GoalDetailsPage />
            </Route>
            {isCollaborator ? (
              <Redirect from="*" exact to={`${path}/coleções`} />
            ) : (
              <Redirect from="*" exact to={`${path}/categorias`} />
            )}
          </Switch>
        </Content>
        <Footer className="text-center">Copyright © {new Date().getFullYear()}</Footer>
      </Layout>
    </Container>
  );
};

export default MainPage;
