import React, { createContext, useState, ReactNode, useContext, useCallback, useEffect } from 'react';

import * as PaymentConditionsService from '@services/payment-condition';
import { PaymentConditions } from '@models/PaymentConditions';
import { message } from 'antd';
import { PaymentsInstallents } from '@models/PaymentInstallments';

interface PaymentConditionsState {
  loadingPaymentConditions: boolean;
  loadingInstallments: boolean;
  paymentConditions: PaymentConditions[];
  paymentConditionsPage: number;
  paymentConditionTotal: number;
  paymentConditionFilters: any;
  loadRequestPaymentConditions: Function;
  updatePaymentConditions: Function;
  errors: string[];
  clearErrors: Function;
  patchCardMaxInstallments: Function;
}

interface PaymentConditionsProviderProps {
  children: ReactNode;
}

const PaymentConditionsContext = createContext<PaymentConditionsState>(Object.assign({}));

const PaymentConditionsProvider: React.FC<PaymentConditionsProviderProps> = ({ children }) => {
  const [paymentConditions, setPaymentConditions] = useState<PaymentConditions[]>([]);
  const [loadingPaymentConditions, setLoadingPaymentConditions] = useState<boolean>(false);
  const [loadingInstallments, setLoadingInstallments] = useState<boolean>(false);
  const [paymentConditionsPage, setPaymentConditionsPage] = useState<number>(0);
  const [paymentConditionTotal, setPaymentConditionTotal] = useState<number>(0);
  const [paymentConditionFilters, setPaymentConditionFilters] = useState({});
  const [errors, setErrors] = useState<string[]>([]);

  const clearErrors = () => {
    setErrors([]);
  };

  const loadRequestPaymentConditions = useCallback(
    async (page: number, filters?: any) => {
      setLoadingPaymentConditions(true);
      setPaymentConditionsPage(page === 0 ? 1 : page);
      try {
        const { data: paymentCondition } = await PaymentConditionsService.get({
          page: page ? page - 1 : 0,
          pageSize: 10,
          ...(filters && { ...filters }),
        });

        setPaymentConditions(paymentCondition.data);
        setPaymentConditionTotal(paymentCondition.total);
      } catch {
        message.error('Erro ao buscar condições de pagamento');
      } finally {
        setLoadingPaymentConditions(false);
      }
    },
    [setPaymentConditions, setLoadingPaymentConditions],
  );

  const updatePaymentConditions = useCallback(
    async (paymentCondition: PaymentConditions, code: number) => {
      setLoadingPaymentConditions(true);
      const { data: updatedPaymentConditions } = await PaymentConditionsService.update(paymentCondition, code);
      if (paymentConditions.length != 0) {
        setPaymentConditions(
          paymentConditions.map((element) => {
            if (element.code === updatedPaymentConditions.code) {
              return updatedPaymentConditions;
            }
            return element;
          }),
        );
      } else {
        setPaymentConditions([updatedPaymentConditions]);
      }
      setLoadingPaymentConditions(false);
    },
    [paymentConditions],
  );

  const patchCardMaxInstallments = useCallback(
    async (params: PaymentsInstallents) => {
      setLoadingInstallments(true);
      try {
        await PaymentConditionsService.patchMaxInstallment(params);
      } catch (e) {
        message.error('Erro ao atualizar quantidade de parcelas no cartão');
      } finally {
        setLoadingInstallments(false);
      }
    },
    [setLoadingPaymentConditions],
  );

  return (
    <PaymentConditionsContext.Provider
      value={{
        paymentConditions,
        loadingPaymentConditions,
        paymentConditionFilters,
        paymentConditionsPage,
        paymentConditionTotal,
        errors,
        loadingInstallments,
        clearErrors,
        loadRequestPaymentConditions,
        updatePaymentConditions,
        patchCardMaxInstallments,
      }}
    >
      {children}
    </PaymentConditionsContext.Provider>
  );
};

const usePaymentConditions = () => {
  const context = useContext(PaymentConditionsContext);
  return context;
};

export { PaymentConditionsProvider, usePaymentConditions };
