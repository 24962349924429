import React, { useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Col, Empty } from 'antd';

import { useSalesRegion } from '@hooks/SalesRegionContext';
import LoadingComponents from '@components/LoadingComponents';
import CepsTable from '../CepsTable';
import CitiesTable from '../CitiesTable';
import StatesTable from '../StatesTable';
import { FullScreenModal } from '@styles/globals';
import Card from '../Card';
import * as S from './styles';

interface OperationalAreasTableProps {
  representativeCode: number;
}
const OperationalAreasTable: React.FC<OperationalAreasTableProps> = ({ representativeCode }) => {
  const { loadingOperationalAreas, loadingSalesRegionCustomers, salesRegionCustomers } = useSalesRegion();
  const [modalIsVisible, setModalIsVisible] = useState(false);
  const history = useHistory();

  const closeModal = useCallback(() => {
    setModalIsVisible(false);
  }, [setModalIsVisible]);

  const showModal = useCallback(() => {
    setModalIsVisible(true);
  }, [setModalIsVisible]);

  const getModalContent = () => {
    if (loadingSalesRegionCustomers) return <LoadingComponents />;

    if (!salesRegionCustomers || salesRegionCustomers.length === 0)
      return (
        <div className="w-100 d-flex" style={{ alignItems: 'center', justifyContent: 'center' }}>
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="Sem clientes disponíveis" />
        </div>
      );

    return salesRegionCustomers.map((c) => (
      <Col className="gutter-row d-flex">
        <Card
          photoUrl={''}
          name={c.name}
          code={c.code.toString()}
          onClick={() => history.replace(`lojistas/perfil/${c.code}`)}
        />
      </Col>
    ));
  };

  if (loadingOperationalAreas) return <LoadingComponents />;

  return (
    <>
      <StatesTable showModal={showModal} representativeCode={representativeCode} />

      <CitiesTable showModal={showModal} representativeCode={representativeCode} />

      <CepsTable showModal={showModal} representativeCode={representativeCode} />

      <FullScreenModal
        destroyOnClose={true}
        footer={null}
        forceRender={false}
        visible={modalIsVisible}
        onOk={closeModal}
        onCancel={closeModal}
        bodyStyle={{ width: '100%', padding: '10% ' }}
      >
        <S.CardContainer gutter={[16, 16]}>{getModalContent()}</S.CardContainer>
      </FullScreenModal>
    </>
  );
};

export default OperationalAreasTable;
