import React, { createContext, ReactNode, useCallback, useContext, useState } from 'react';

import * as PriceTableService from '@services/priceTable';
import { PriceTable } from '@models/PriceTable';
import { message } from 'antd';
import { PriceTableIntegrationFilter } from '@models/PriceTableIntegrationFilter';

interface PriceTableState {
  loading: boolean;
  loadingUpdate: boolean;
  loadingDetails: boolean;
  loadingPriceTableIntegrationFilter: boolean;

  priceTables: PriceTable[];
  priceTableIntegrationFilter: PriceTableIntegrationFilter;
  priceTableCurrentPage: number;
  priceTableTotal: number;
  priceTableFilters: any;

  loadPriceTables: Function;
  loadPriceTableIntegrationFilter: Function;
  loadPriceTableDetails: Function;

  updatePriceTables: Function;
  updatePriceTableObservation: Function;
}

interface PriceTableProviderProps {
  children: ReactNode;
}

export const PriceTableContext = createContext<PriceTableState>({} as PriceTableState);

const PriceTableProvider: React.FC<PriceTableProviderProps> = ({ children }) => {
  const [priceTables, setPriceTables] = useState<PriceTable[]>([]);
  const [priceTableIntegrationFilter, setPriceTableIntegrationFilter] = useState<PriceTableIntegrationFilter>(
    {} as PriceTableIntegrationFilter,
  );

  const [priceTableFilters, setPriceTableFilters] = useState({} as any);
  const [priceTableCurrentPage, setPriceTableCurrentPage] = useState<number>(0);
  const [priceTableTotal, setPriceTableTotal] = useState<number>(0);

  const [loading, setLoading] = useState<boolean>(false);
  const [loadingPriceTableIntegrationFilter, setLoadingPriceTableIntegrationFilter] = useState<boolean>(false);

  const [loadingUpdate, setLoadingUpdate] = useState<boolean>(false);
  const [loadingDetails, setLoadingDetails] = useState<boolean>(false);

  const loadPriceTables = useCallback(
    async (page: number, filters?: any) => {
      setLoading(true);
      setPriceTableCurrentPage(page === 0 ? 1 : page);

      try {
        const { data: _priceTables } = await PriceTableService.get({
          page: page ? page - 1 : 0,
          pageSize: 10,
          ...(filters && { ...filters }),
        });
        setPriceTables(_priceTables.data);
        setPriceTableTotal(_priceTables.total);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    },
    [setPriceTables, setLoading],
  );

  const loadPriceTableIntegrationFilter = useCallback(async () => {
    setLoadingPriceTableIntegrationFilter(true);

    const { data: _integrationFilter } = await PriceTableService.getPriceTableIntegrationFilter();

    setPriceTableIntegrationFilter(_integrationFilter);
    setLoadingPriceTableIntegrationFilter(false);
  }, [setPriceTableIntegrationFilter, setLoadingPriceTableIntegrationFilter]);

  const loadPriceTableDetails = useCallback(
    async (priceTableCode: number) => {
      setLoadingDetails(true);
      try {
        const { data: _priceTable } = await PriceTableService.getDetailsByCode(priceTableCode);

        const index = priceTables.findIndex((priceTable) => priceTable.code === priceTableCode);

        if (index !== -1 && _priceTable) {
          priceTables[index] = _priceTable;
          setPriceTables([...priceTables]);
        }
      } catch (e) {
        console.error(e);
        message.error('Erro ao buscar detalhes da tabela de preço!');
      } finally {
        setLoadingDetails(false);
      }
    },
    [priceTables, setPriceTables, setLoadingDetails],
  );

  const updatePriceTables = useCallback(
    async (priceTableCodeList: string[]) => {
      setLoadingUpdate(true);

      try {
        const { data: _priceTables } = await PriceTableService.updatePriceTables({ priceTableCodeList });
        message.success('Tabelas de Preço vinculadas com sucesso!');
      } catch (e) {
        message.error('Erro ao gravar Tabelas de Preço!');
      } finally {
        setLoadingUpdate(false);
      }
    },
    [setPriceTables, setLoadingUpdate],
  );

  const updatePriceTableObservation = useCallback(
    async (priceTableCode: number, observation: string) => {
      setLoadingUpdate(true);

      try {
        const { data: _priceTable } = await PriceTableService.updatePriceTableObservation(priceTableCode, observation);

        const index = priceTables.findIndex((priceTable) => priceTable.code === priceTableCode);

        if (index !== -1 && _priceTable) {
          priceTables[index].observation = _priceTable.observation;
          setPriceTables([...priceTables]);
          message.success('Observação alterada com sucesso!');
        }
      } catch (e) {
        message.error('Erro ao alterar observação!');
      } finally {
        setLoadingUpdate(false);
      }
    },
    [priceTables, setPriceTables, setLoadingUpdate],
  );

  return (
    <PriceTableContext.Provider
      value={{
        loading,
        loadingUpdate,
        loadingDetails,
        loadingPriceTableIntegrationFilter,
        priceTableIntegrationFilter,
        priceTables,
        priceTableCurrentPage,
        priceTableTotal,
        priceTableFilters,
        loadPriceTables,
        loadPriceTableIntegrationFilter,
        loadPriceTableDetails,
        updatePriceTables,
        updatePriceTableObservation,
      }}
    >
      {children}
    </PriceTableContext.Provider>
  );
};

const usePriceTable = () => {
  return useContext(PriceTableContext);
};

export { PriceTableProvider, usePriceTable };
