import React, { createContext, useState, useContext, useCallback, useMemo } from 'react';

import * as CollectionService from '@services/collection';
import { Collection } from '@models/Collection';
import { CollectionDetailsFiltersType } from '@models/CollectionDetailsFilters';
import { Subcategory } from '@models/Subcategory';
import { useProduct } from '@hooks/ProductContext';
import { message } from 'antd';
import { INITIAL_PAGE } from '@utils/constants';

interface CollectionState {
  collections: Collection[];
  allCollections: Collection[];
  collectionFilters: any;
  loading: boolean;
  collectionDetailsFilters: CollectionDetailsFiltersType;
  loadCollections: Function;
  createCollection: Function;
  updateCollection: Function;
  filterCollections: Function;
  removeCategoryFilterCollectionDetails: Function;
  removeSubCategoryFilterCollectionDetails: Function;
  removeColorFilterCollectionDetails: Function;
  removeSizeFilterCollectionDetails: Function;
  resetFilterCollectionDetails: Function;
  loadAllCollections: Function;
}

interface CollectionProviderProps {}

const initialFiltersState = {
  categoryFilter: null,
  subCategoryFilter: null,
  colorFilter: null,
  sizeFilter: null,
};

export const CollectionContext = createContext<CollectionState | any>({});

export type FilterCollectionTypes = {
  description: string;
  initialDate: string;
  finalDate: string;
};

const CollectionProvider: React.FC<CollectionProviderProps> = ({ children }) => {
  const { loadProductsByCollectionId } = useProduct();
  const [collections, setCollections] = useState<any[]>([]);
  const [allCollections, setAllCollections] = useState<any[]>([]);

  const [collectionDetailsFilters, setCollectionDetailsFilters] =
    useState<CollectionDetailsFiltersType>(initialFiltersState);
  const [collectionFilters, setCollectionFilters] = useState<any>({} as any);

  const [collectionCurrentPage, setCollectionCurrentPage] = useState<number>(INITIAL_PAGE);
  const [collectionsTotal, setCollectionsTotal] = useState<number>(0);

  const [loading, setLoading] = useState<boolean>(false);

  const loadCollections = useCallback(
    async (page: number, filters?: FilterCollectionTypes) => {
      setLoading(true);
      setCollectionCurrentPage(page === 0 ? 1 : page);
      try {
        const { data: _collections } = await CollectionService.get({
          page: page ? page - 1 : 0,
          pageSize: 10,
          ...(filters && { ...filters }),
        });
        setCollections(_collections.data);
        setCollectionsTotal(_collections.total);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    },
    [collections],
  );

  const loadAllCollections = useMemo(
    () => async (page: number, filters?: FilterCollectionTypes) => {
      setCollectionCurrentPage(page === 0 ? 1 : page);
      try {
        const { data: _collections } = await CollectionService.get({
          page: page ? page - 1 : 0,
          pageSize: 10,
          listAll: true,
          ...(filters && { ...filters }),
        });
        setAllCollections(_collections.data);
      } catch (error) {
        console.error(error);
      }
    },
    [allCollections],
  );

  const filterCollections = async (filters: Partial<FilterCollectionTypes>) => {
    if (!Object.keys(filters).length) return loadCollections(1);
    setLoading(true);
    if (filters) setCollectionFilters(filters);
    else setCollectionFilters({});
    try {
      const { data: _collections } = await CollectionService.get({
        page: 0,
        pageSize: 10,
        ...(filters && { ...filters }),
      });
      setCollections(_collections.data);
      setCollectionsTotal(_collections.total);
    } catch {
      message.error('Erro ao filtrar coleções');
    } finally {
      setLoading(false);
    }
  };

  const createCollection = useCallback(
    async (newCollection: Partial<Collection>) => {
      setLoading(true);

      try {
        const { data: _newCollection } = await CollectionService.saveCollection(newCollection);

        setCollections([...collections, _newCollection]);

        await loadCollections(0);
      } catch (error) {
        console.log('error at collection context', error);
      }

      setLoading(false);
    },
    [collections],
  );

  const updateCollection = useCallback(
    async (collectionId: number, collectionToUpdate: Partial<Collection>) => {
      setLoading(true);

      try {
        const { data: _collectionUpdated } = await CollectionService.updateCollection(collectionId, {
          collectionId,
          ...collectionToUpdate,
        });

        const _collections = collections;
        const index = _collections.findIndex((collection) => collection.collectionId === collectionId);
        _collections[index] = _collectionUpdated;

        setCollections(_collections);

        await loadCollections(0);
      } catch (error) {
        console.log('error at collection context', error);
      } finally {
        setLoading(false);
      }
    },
    [collections],
  );

  const filterCollectionDetails = useCallback(
    async (filters: CollectionDetailsFiltersType, collectionId: number) => {
      setLoading(true);

      setCollectionDetailsFilters(filters);

      await loadProductsByCollectionId(collectionId, filters);

      setLoading(false);
    },
    [collectionDetailsFilters, setCollectionDetailsFilters],
  );

  const removeCategoryFilterCollectionDetails = useCallback(
    async (collectionId: number) => {
      setLoading(true);

      await loadProductsByCollectionId(collectionId, { ...collectionDetailsFilters, categoryFilter: null });

      setCollectionDetailsFilters({ ...collectionDetailsFilters, categoryFilter: null });

      setLoading(false);
    },
    [collectionDetailsFilters, setCollectionDetailsFilters],
  );

  const removeSubCategoryFilterCollectionDetails = useCallback(
    async (subCategoryId: number, collectionId: number) => {
      setLoading(true);

      const filteredSubcategories = collectionDetailsFilters.subCategoryFilter.filter(
        (sub: Subcategory) => sub.subCategoryId !== subCategoryId,
      );

      await loadProductsByCollectionId(collectionId, {
        ...collectionDetailsFilters,
        subCategoryFilter: filteredSubcategories,
      });

      setCollectionDetailsFilters({ ...collectionDetailsFilters, subCategoryFilter: filteredSubcategories });

      setLoading(false);
    },
    [collectionDetailsFilters, setCollectionDetailsFilters],
  );

  const removeColorFilterCollectionDetails = useCallback(
    async (colorCodeToRemove: string, collectionId: number) => {
      setLoading(true);

      const filteredColors = collectionDetailsFilters.colorFilter.filter(({ code }: any) => code !== colorCodeToRemove);

      await loadProductsByCollectionId(collectionId, { ...collectionDetailsFilters, colorFilter: filteredColors });

      setCollectionDetailsFilters({ ...collectionDetailsFilters, colorFilter: filteredColors });

      setLoading(false);
    },
    [collectionDetailsFilters, setCollectionDetailsFilters],
  );

  const removeSizeFilterCollectionDetails = useCallback(
    async (sizeToRemove: string, collectionId: number) => {
      setLoading(true);

      const filteredSizes = collectionDetailsFilters.sizeFilter.filter(({ size }: any) => size !== sizeToRemove);

      await loadProductsByCollectionId(collectionId, { ...collectionDetailsFilters, sizeFilter: filteredSizes });

      setCollectionDetailsFilters({ ...collectionDetailsFilters, sizeFilter: filteredSizes });

      setLoading(false);
    },
    [collectionDetailsFilters, setCollectionDetailsFilters],
  );

  const resetFilterCollectionDetails = useCallback(
    async (collectionId?: number) => {
      setLoading(true);

      if (collectionId) await loadProductsByCollectionId(collectionId, initialFiltersState);

      setCollectionDetailsFilters(initialFiltersState);

      setLoading(false);
    },
    [collectionDetailsFilters, setCollectionDetailsFilters],
  );
  return (
    <CollectionContext.Provider
      value={{
        collections,
        collectionFilters,
        loading,
        collectionDetailsFilters,
        collectionCurrentPage,
        collectionsTotal,
        allCollections,
        loadCollections,
        createCollection,
        filterCollections,
        updateCollection,
        filterCollectionDetails,
        removeCategoryFilterCollectionDetails,
        removeSubCategoryFilterCollectionDetails,
        removeColorFilterCollectionDetails,
        removeSizeFilterCollectionDetails,
        resetFilterCollectionDetails,
        loadAllCollections,
      }}
    >
      {children}
    </CollectionContext.Provider>
  );
};

const useCollection = () => {
  return useContext(CollectionContext);
};

export { CollectionProvider, useCollection };
