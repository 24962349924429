import * as api from './api';

export function get(params: any): Promise<any> {
  return api.get('orders', params);
}

export function getFailed(params: any): Promise<any> {
  return api.get('orders/failed', params);
}

export function getByCode(code: number): Promise<any> {
  return api.get(`orders/${code}`);
}

export function getFailedDetail(sequence: number): Promise<any> {
  return api.get(`orders/failed/${sequence}`);
}