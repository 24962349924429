import React from 'react';

import SellerCardHeader from '../SellerCardHeader';
import SellerCardContent from '../SellerCardContent';

import * as S from './styles';
import Card from '../Card';
import { GoalItem } from '@models/GoalItem';

interface SellerCardProps {
  goalItem: GoalItem;
}
const SellerCard: React.FC<SellerCardProps> = ({ goalItem }) => {
  return (
    <Card>
      <SellerCardHeader goalItem={goalItem} />
      <S.Divider className="mt-4 mb-4" />
      <SellerCardContent goalItem={goalItem} />
    </Card>
  );
};

export default SellerCard;
