import React, { useEffect, useState } from 'react';
import { Row, Table } from 'antd';

import { getImageOrAvatar } from '@utils/get-image-or-avatar';
import { useSalesRegion } from '@hooks/SalesRegionContext';
import LoadingComponents from '@components/LoadingComponents';
import RepresentativeOperationalAreasTable from '../RepresentativeOperationalAreasTable';
import TableFilters from '@components/TableFilters';
import { SearchOutlined } from '@ant-design/icons';
import '../../styles.css';

interface RepresentativesTableProps {
  brandId: number;
}

const RepresentativesTable: React.FC<RepresentativesTableProps> = ({ brandId }) => {
  const {
    loadingSalesRegionRepresentatives,
    salesRegionRepresentatives,
    loadSalesRegionsRepresentatives,
    salesRepresentativeFilters,
    salesRepresentativePage,
    salesRepresentativeTotal,
  } = useSalesRegion();
  const [expandedRowKeys, setExpandedRowKeys] = useState<any[]>([]);

  useEffect(() => {
    loadSalesRegionsRepresentatives(0, brandId);
  }, []);

  const representativeColumns = [
    {
      title: 'Cód.',
      dataIndex: 'code',
      width: '120px',
      align: 'center' as 'center',
      sorter: true,
    },
    {
      title: 'Representante',
      render: (representative: any) => (
        <Row align="middle">
          {getImageOrAvatar(representative.representativePhotoUrl, representative.name)}
          <strong className="ml-3">{representative.name}</strong>
        </Row>
      ),
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }: any) => (
        <TableFilters
          inputPlaceholder={'Filtrar pelo Representante'}
          inputValue={selectedKeys[0]}
          onChangeInputValue={setSelectedKeys}
          onFilter={confirm}
          onClearFilters={clearFilters}
        />
      ),
      filterIcon: () => <SearchOutlined />,
      onFilter: (value: any, record: any) => {
        return record.name ? record.name.toString().toLowerCase().includes(value.toLowerCase()) : '';
      },
      sorter: true,
    },
  ];

  const onTableRowExpand = (expanded: any, record: any) => {
    const keys = [];

    if (expanded) {
      keys.push(record.code);
    }

    setExpandedRowKeys(keys);
  };

  const handleTableChange = (pagination: any, filters: any, sorter: any) => {
    if (sorter.hasOwnProperty('column')) {
      salesRepresentativeFilters.field = `${sorter.field}`;
      salesRepresentativeFilters.order = `${sorter.order}`;
    }

    loadSalesRegionsRepresentatives(pagination.current, brandId, salesRepresentativeFilters);
  };

  return (
    <Table
      className="mt-4 custom-table"
      columns={representativeColumns}
      dataSource={salesRegionRepresentatives}
      rowKey={(representative) => representative.code}
      expandable={{
        expandedRowRender: (representative) => (
          <RepresentativeOperationalAreasTable
            operationalAreas={representative.salesRegions}
            selectedRepresentatives={expandedRowKeys}
          />
        ),
        onExpand: onTableRowExpand,
      }}
      loading={loadingSalesRegionRepresentatives}
      expandedRowKeys={expandedRowKeys}
      pagination={{
        style: { marginTop: '24px' },
        showSizeChanger: false,
        current: salesRepresentativePage,
        pageSize: 10,
        total: salesRepresentativeTotal,
      }}
      scroll={{ x: 800 }}
      onChange={handleTableChange}
    />
  );
};

export default RepresentativesTable;
