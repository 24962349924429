import React, { useEffect, useState } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';

import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Form, Modal, message } from 'antd';

import OfficialStoreProfileHeader from './components/OfficialStoreProfileHeader';
import { useOfficialStore } from '@hooks/OfficialStoreContext';
import OfficialStoreProfileContent from './components/OfficialStoreProfileContent';
import Loading from '@components/Loading';
import { usePriceTable } from '@hooks/PriceTableContext';

interface MatchParams {
  id: string;
}

const OfficialStoreProfilePage: React.FC = () => {
  const [form] = Form.useForm();
  const { params } = useRouteMatch<MatchParams>();
  const history = useHistory();
  const { actualOfficialStore, updateOfficialStore, findOfficialStoreById, loading } = useOfficialStore();

  const [logoUrl, setLogoUrl] = useState<string>('');

  useEffect(() => {
    findOfficialStoreById(parseInt(params.id));
  }, []);

  useEffect(() => {
    if (!actualOfficialStore) return;

    form.setFieldsValue(actualOfficialStore);
  }, [actualOfficialStore]);

  const onUpdateOfficialStore = async (values: any) => {
    const logo = logoUrl ? logoUrl : actualOfficialStore.logoUrl;

    if (!values.isDeliveryImmediately && !values.isMakeToOrder)
      return message.error('Preencha ao menos um Tipo de Venda!');

    const officialStoreToUpdate = { ...values, logoUrl: logo };

    const updated = await updateOfficialStore(parseInt(params.id), officialStoreToUpdate);

    if (updated) {
      message.success('Loja atualizada com sucesso!');
      history.go(-1);
    }
  };

  const onSubmit = (values: any) => {
    Modal.confirm({
      title: 'Atualizar Loja Oficial',
      icon: <ExclamationCircleOutlined />,
      content: 'Deseja mesmo prosseguir?',
      okText: 'Sim',
      cancelText: 'Não',
      onOk: () => onUpdateOfficialStore(values),
    });
  };

  if (loading) return <Loading />;

  return (
    <Form layout="vertical" form={form} onFinish={onSubmit}>
      <OfficialStoreProfileHeader officialStore={actualOfficialStore} />
      <OfficialStoreProfileContent officialStore={actualOfficialStore} logoUrl={logoUrl} setLogoUrl={setLogoUrl} />
    </Form>
  );
};

export default OfficialStoreProfilePage;
