import React, { useCallback, useEffect, useState } from 'react';

import { Row, Col, Typography, Drawer } from 'antd';
import FiltersForm from '@components/FiltersForm';
import HeaderButton from '@components/HeaderButton';
import { FilterFilled } from '@ant-design/icons';
import ProductsFiltersForm from '@components/ProductsFiltersForm';
import { useColor } from '@hooks/ColorContext';
import Loading from '@components/Loading';
import { useCollection } from '@hooks/CollectionContext';
import { useCategory } from '@hooks/CategoryContext';
import { useReference } from '@hooks/ReferenceContext';
import ListFilters, { ListFilterType } from '@components/ListFilters';

const { Title } = Typography;

const filtersNameMap: any = {
  name: 'Descrição',
  reference: 'Referência',
  collectionId: 'Cód. Coleção',
  subCategoryId: 'Cód. SubCategoria',
  colorCode: 'Cód. Cor',
  size: 'Tamanho',
};

const ProductsHeader: React.FC = () => {
  const [filtersDrawerIsVisible, setfiltersDrawerIsVisible] = useState<boolean>(false);

  const { loadColorsGroupedByName, loading: loadingColors, colors } = useColor();
  const { loadReferences, loadingReferences, referenceIsActive, referencesFilters } = useReference();
  const { loadCollections, loading: loadingCollections, collections } = useCollection();
  const { loadRequestCategory, loading: loadingCategories, categories } = useCategory();

  const [filters, setFilters] = useState<ListFilterType[]>([]);

  useEffect(() => {
    loadColorsGroupedByName();
    loadCollections(0, { listAll: true });
    loadRequestCategory();
  }, []);

  useEffect(() => {
    const _filters: ListFilterType[] = [];
    if (referencesFilters && Object.keys(referencesFilters).length) {
      for (let key of Object.keys(referencesFilters)) {
        if (referencesFilters[key]) {
          _filters.push({
            value: `${filtersNameMap[key]}: ${referencesFilters[key]}`,
            key,
          });
        }
      }
    }
    setFilters(_filters);
  }, [referencesFilters]);

  const handleCloseFiltersDrawer = useCallback(() => {
    setfiltersDrawerIsVisible(false);
  }, [setfiltersDrawerIsVisible]);

  const handleShowFiltersDrawer = useCallback(() => {
    setfiltersDrawerIsVisible(true);
  }, [setfiltersDrawerIsVisible]);

  const onSubmitFiltersForm = useCallback(
    (values: any) => {
      loadReferences(0, referenceIsActive, values);
    },
    [referenceIsActive],
  );

  const onClearFiltersForm = useCallback(() => {
    loadReferences(0, referenceIsActive);
  }, []);

  const onRemoveFilter = ({ key }: ListFilterType) => {
    const newFilters = { ...referencesFilters, [key]: undefined };

    loadReferences(0, referenceIsActive, newFilters);
  };

  return (
    <>
      <Row className="mt-8" justify="space-between">
        <Col>
          <Title level={2}>Produtos</Title>
        </Col>

        <Col>
          <Row>
            <Col>
              <ListFilters filters={filters} onRemove={onRemoveFilter} />
            </Col>

            <Col>
              <HeaderButton
                text="Filtros"
                onClick={handleShowFiltersDrawer}
                Icon={<FilterFilled style={{ fontSize: '20px', color: 'var(--primary-color)' }} />}
              />
            </Col>
          </Row>
        </Col>
        <Drawer
          title="Filtros"
          placement="left"
          onClose={handleCloseFiltersDrawer}
          visible={filtersDrawerIsVisible}
          width={400}
        >
          <FiltersForm
            onCloseDrawer={handleCloseFiltersDrawer}
            onSubmit={onSubmitFiltersForm}
            onClearFilters={onClearFiltersForm}
            filtersForm={<ProductsFiltersForm colors={colors} collections={collections} categories={categories} />}
          />
        </Drawer>
      </Row>
    </>
  );
};

export default ProductsHeader;
