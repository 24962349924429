import React from 'react';
import { Router } from 'react-router-dom';

import history from './services/history';
import GlobalStyle from './styles/globals';
import { ReactKeycloakProvider } from '@react-keycloak/web';
import { keycloak, keycloakProviderInitConfig } from './utils/keycloack-auth';

import Routes from './routes';
import { OrderProvider } from '@hooks/OrdersContext';
import { CategoryProvider } from '@hooks/CategoryContext';
import { ManufacturerProvider } from '@hooks/ManufacturerContext';
import { PaymentConditionsProvider } from '@hooks/PaymentConditionsContext';
import { CollaboratorProvider } from '@hooks/CollaboratorContext';
import { CollectionProvider } from '@hooks/CollectionContext';
import { ProductProvider } from '@hooks/ProductContext';
import { ClassificationProvider } from '@hooks/ClassificationContext';
import { ColorProvider } from '@hooks/ColorContext';
import { OfficialStoreProvider } from '@hooks/OfficialStoreContext';
import { OfficialStoreOrderClassificationProvider } from '@hooks/OfficialStoreOrderClassificationContext';
import { RepresentativeProvider } from '@hooks/RepresentativeContext';
import { BrandProvider } from '@hooks/BrandContext';
import { PriceTableProvider } from '@hooks/PriceTableContext';
import { OfficialStoreRepresentativesProvider } from '@hooks/OfficialStoreRepresentativesContext';
import { SellerProvider } from '@hooks/SellerContext';
import { OfficialStoreQuestionsProvider } from '@hooks/OfficialStoreQuestionsContext';
import { CustomerProvider } from '@hooks/CustomerContext';
import { ParametersProvider } from '@hooks/ParametersContext';
import { ReferenceProvider } from '@hooks/ReferenceContext';
import { SalesRegionProvider } from '@hooks/SalesRegionContext';
import { ImagesProvider } from '@hooks/ImagesContext';
import { GoalProvider } from '@hooks/GoalContext';
import { VisitsProvider } from '@hooks/VisitsContext';
import { OfficialStoreSellerProvider } from '@hooks/OfficialStoreSellerContext';

const App: React.FC = () => {
  return (
    <ReactKeycloakProvider
      authClient={keycloak}
      initOptions={keycloakProviderInitConfig}
      onTokens={({ token }: any) => {
        localStorage.setItem('token', token);
      }}
    >
      <GoalProvider>
        <SalesRegionProvider>
          <ImagesProvider>
            <ReferenceProvider>
              <VisitsProvider>
                <BrandProvider>
                  <CustomerProvider>
                    <ParametersProvider>
                      <SellerProvider>
                        <PriceTableProvider>
                          <RepresentativeProvider>
                            <OfficialStoreProvider>
                              <OfficialStoreOrderClassificationProvider>
                                <OfficialStoreRepresentativesProvider>
                                  <OfficialStoreSellerProvider>
                                    <OfficialStoreQuestionsProvider>
                                      <ColorProvider>
                                        <ClassificationProvider>
                                          <ProductProvider>
                                            <CollectionProvider>
                                              <OrderProvider>
                                                <ManufacturerProvider>
                                                  <CategoryProvider>
                                                    <PaymentConditionsProvider>
                                                      <CollaboratorProvider>
                                                        <Router history={history}>
                                                          <Routes />
                                                        </Router>
                                                      </CollaboratorProvider>
                                                    </PaymentConditionsProvider>
                                                    <GlobalStyle />
                                                  </CategoryProvider>
                                                </ManufacturerProvider>
                                              </OrderProvider>
                                            </CollectionProvider>
                                          </ProductProvider>
                                        </ClassificationProvider>
                                      </ColorProvider>
                                    </OfficialStoreQuestionsProvider>
                                  </OfficialStoreSellerProvider>
                                </OfficialStoreRepresentativesProvider>
                              </OfficialStoreOrderClassificationProvider>
                            </OfficialStoreProvider>
                          </RepresentativeProvider>
                        </PriceTableProvider>
                      </SellerProvider>
                    </ParametersProvider>
                  </CustomerProvider>
                </BrandProvider>
              </VisitsProvider>
            </ReferenceProvider>
          </ImagesProvider>
        </SalesRegionProvider>
      </GoalProvider>
    </ReactKeycloakProvider>
  );
};

export default App;
