import * as api from './api';

export function get(options?: any) {
  return api.get('price_table', options);
}

export function getPriceTableIntegrationFilter(): Promise<any> {
  return api.get('price_table/filter');
}

export function getDetailsByCode(priceTableCode: number): Promise<any> {
  return api.get(`price_table/${priceTableCode}`);
}

export function updatePriceTables(params: any): Promise<any> {
  return api.put('price_table/filter', params);
}

export function updatePriceTableObservation(priceTableCode: number, value: string): Promise<any> {
  return api.patch(`price_table/${priceTableCode}`, { property: 'observation', value });
}
