import * as api from './api';

export function get(options: any) {
  return api.get(`references`, options);
}

export function getByCollectionId(collectionId: number, options: any) {
  return api.get(`references/collection/${collectionId}`, options);
}

export function getPack(referenceCode: string) {
  return api.get(`references/pack/${referenceCode}`);
}

export function getReferenceProducts(referenceCode: string) {
  return api.get(`products/${referenceCode}`);
}

export function updateReferenceStatus(referenceCode: string, isActive: boolean) {
  return api.patch(`references/${referenceCode}`, { property: 'isActive', value: isActive });
}

export function updatePack(referenceCode: string, body: any) {
  return api.put(`references/pack/${referenceCode}`, body);
}
