import React from 'react';
import moment from 'moment';
import { Table, Tag, Typography } from 'antd';

import { useRepresentative } from '@hooks/RepresentativeContext';
import { returnOrderStatus } from '@utils/order-status';

const { Title, Text } = Typography;
interface OrdersProps {}

const Orders: React.FC<OrdersProps> = () => {
  const { orders, ordersTotal, loadOrdersByRepresentativeIdPaginated, actualRepresentative } = useRepresentative();

  const columns = [
    {
      title: 'Nº Pedido',
      dataIndex: 'orderCode',
      align: 'center' as 'center',
      sorter: (pedidoA: any, pedidoB: any) => pedidoA.orderCode - pedidoB.orderCode,
    },
    {
      title: 'Nome da Loja',
      dataIndex: 'customerName',
      sorter: (pedidoA: any, pedidoB: any) => pedidoA.customerName.trim().localeCompare(pedidoB.customerName.trim()),
    },
    {
      title: 'Representante',
      dataIndex: 'representativeName',
      sorter: (pedidoA: any, pedidoB: any) =>
        pedidoA.representativeName.trim().localeCompare(pedidoB.representativeName.trim()),
    },
    {
      title: 'Condição de Pagamento',
      dataIndex: 'paymentConditionName',
      align: 'center' as 'center',
      sorter: (pedidoA: any, pedidoB: any) =>
        pedidoA.paymentConditionName.trim().localeCompare(pedidoB.paymentConditionName.trim()),
    },
    {
      title: 'Total',
      dataIndex: 'totalAmountOrder',
      align: 'right' as 'right',
      render: (text: string) => <Text>R$ {Number(text).toFixed(2).toString().replace('.', ',')}</Text>,
      sorter: (pedidoA: any, pedidoB: any) => pedidoA.totalAmountOrder - pedidoB.totalAmountOrder,
    },
    {
      title: 'Data Pedido',
      dataIndex: 'orderDate',
      render: (text: string) => <Text>{moment.utc(text).format('DD/MM/YYYY')}</Text>,
      sorter: (pedidoA: any, pedidoB: any) => {
        const date1 = new Date(pedidoA.orderDate);
        const date2 = new Date(pedidoB.orderDate);

        return date1.getTime() - date2.getTime();
      },
    },
    {
      title: 'Status',
      dataIndex: 'statusOrder',
      align: 'center' as 'center',
      render: (text: string) => {
        const status = returnOrderStatus(text);

        if (text === 'Attended') return <Tag color="green">{status}</Tag>;

        if (text === 'InProgress') return <Tag color="yellow">{status}</Tag>;

        if (text === 'Canceled') return <Tag color="red">{status}</Tag>;

        return <Tag>{status}</Tag>;
      },
      sorter: (pedidoA: any, pedidoB: any) => pedidoA.statusOrder.trim().localeCompare(pedidoB.statusOrder.trim()),
    },
  ];

  return (
    <div className="mt-4 ml-4">
      <Title level={5}>Pedidos</Title>
      <Table
        columns={columns}
        dataSource={orders}
        scroll={{ x: 800 }}
        pagination={{
          pageSize: 8,
          total: ordersTotal,
          showSizeChanger: false,
          showQuickJumper: false,
          onChange: (page: number) => loadOrdersByRepresentativeIdPaginated(actualRepresentative.code, page),
        }}
      />
    </div>
  );
};

export default Orders;
