import React from 'react';

import { Image, Menu, Row } from 'antd';
import { OfficialStore } from '@models/OfficialStore';
import { Link } from 'react-router-dom';

interface OfficialStoreNavigationProps {
  officialStore: OfficialStore;
}

const OfficialStoreNavigation: React.FC<OfficialStoreNavigationProps> = ({ officialStore }) => {
  return (
    <Menu style={{ width: '100%', margin: '0', padding: '40px 8px', height: '100vh' }} theme="dark" mode="inline">
      <Row align="middle" justify="center" style={{ flexDirection: 'column' }}>
        <div style={{ width: '100%', height: '8vh', backgroundColor: '#510c76', margin: '-40px 0 0 0' }}></div>

        <Image width={100} src={officialStore.logoUrl} alt="" preview={false} style={{ margin: '-30px 0 0 0' }} />

        <div>
          <h3 style={{ color: '#fff', margin: '28px 0', textAlign: 'center' }}>{officialStore.description}</h3>
          <p style={{ textAlign: 'center' }}>#{officialStore.officialStoreId}</p>
        </div>
      </Row>

      <Menu.Item key="Perfil Da Loja">
        <Link to={`/painel/lojas-oficiais/perfil/${officialStore.officialStoreId}`}>Perfil Da Loja</Link>
      </Menu.Item>

      <Menu.Item key="Coleções">
        <Link to={`/painel/lojas-oficiais/perfil/${officialStore.officialStoreId}/colecoes`}>Coleções</Link>
      </Menu.Item>

      <Menu.Item key="Menu da Loja">
        <Link to={`/painel/lojas-oficiais/perfil/${officialStore.officialStoreId}/menu`}>Menu da Loja</Link>
      </Menu.Item>

      <Menu.Item key="Pagamento">
        <Link to={`/painel/lojas-oficiais/perfil/${officialStore.officialStoreId}/pagamento`}>Pagamento</Link>
      </Menu.Item>

      <Menu.Item key="Classe dos Pedidos">
        <Link to={`/painel/lojas-oficiais/perfil/${officialStore.officialStoreId}/pedidos/classificacoes`}>
          Classe dos Pedidos
        </Link>
      </Menu.Item>

      <Menu.Item key="Representantes">
        <Link to={`/painel/lojas-oficiais/perfil/${officialStore.officialStoreId}/representantes`}>Representantes</Link>
      </Menu.Item>

      <Menu.Item key="Vendedores">
        <Link to={`/painel/lojas-oficiais/perfil/${officialStore.officialStoreId}/vendedores`}>Vendedores</Link>
      </Menu.Item>

      <Menu.Item key="Perguntas">
        <Link to={`/painel/lojas-oficiais/perfil/${officialStore.officialStoreId}/perguntas`}>Perguntas</Link>
      </Menu.Item>

      <Menu.Item key="Sair da Loja Oficial">
        <Link to={`/painel/lojas-oficiais/perfil/${officialStore.officialStoreId}`}>Sair da Loja Oficial</Link>
      </Menu.Item>
    </Menu>
  );
};

export default OfficialStoreNavigation;
