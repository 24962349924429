import React, { createContext, ReactNode, useCallback, useContext, useState } from 'react';
import * as SalesRegionService from '@services/salesRegion';
import { message } from 'antd';
import { SalesRegionRepresentative } from '@models/SalesRegionRepresentative';
import { OperationalArea } from '@models/OperationalArea';
import { Customer } from '@models/Customer';

interface SalesRegionState {
  salesRegionRepresentatives: SalesRegionRepresentative[];
  loadingSalesRegionRepresentatives: boolean;
  loadSalesRegionsRepresentatives: Function;
  salesRepresentativeFilters: SalesRegionRepresentativesFilters;
  salesRepresentativePage: number;
  salesRepresentativeTotal: number;

  operationalArea: OperationalArea;
  loadingOperationalAreas: boolean;
  loadOperationalArea: Function;

  salesRegionCustomers: Customer[];
  loadingSalesRegionCustomers: boolean;
  loadSalesRegionsCustomers: Function;
}

interface SalesRegionProviderProps {
  children: ReactNode;
}

interface SalesRegionRepresentativesFilters {
  field: string;
  order: string;
}

export const SalesRegionContext = createContext<SalesRegionState>({} as SalesRegionState);

const SalesRegionProvider: React.FC<SalesRegionProviderProps> = ({ children }) => {
  const [salesRegionRepresentatives, setSalesRegionRepresentatives] = useState<SalesRegionRepresentative[]>([]);
  const [loadingSalesRegionRepresentatives, setLoadingSalesRegionRepresentatives] = useState<boolean>(false);
  const [salesRepresentativeFilters, setSalesRepresentativeFilters] = useState<any>({} as any);
  const [salesRepresentativePage, setSalesRepresentativePage] = useState<number>(0);
  const [salesRepresentativeTotal, setSalesRepresentativeTotal] = useState<number>(0);

  const [operationalArea, setOperationalArea] = useState<OperationalArea>({} as OperationalArea);
  const [loadingOperationalAreas, setLoadingOperationalAreas] = useState<boolean>(false);

  const [salesRegionCustomers, setSalesRegionCustomers] = useState<Customer[]>([]);
  const [loadingSalesRegionCustomers, setLoadingSalesRegionCustomers] = useState<boolean>(false);

  const loadSalesRegionsRepresentatives = useCallback(
    async (page: number, brandId: number, filters?: SalesRegionRepresentativesFilters) => {
      setLoadingSalesRegionRepresentatives(true);
      setSalesRepresentativePage(page === 0 ? 1 : page);

      try {
        const { data: _salesRegionRepresentatives } = await SalesRegionService.getByBrandId(brandId, {
          page: page ? page - 1 : 0,
          pageSize: 10,
          ...(filters && { ...filters }),
        });
        setSalesRegionRepresentatives(_salesRegionRepresentatives.data);
        setSalesRepresentativeTotal(_salesRegionRepresentatives.total);
      } catch (e: any) {
        message.warning('Ocorreu um erro ao buscar os representantes da marca.');
      } finally {
        setLoadingSalesRegionRepresentatives(false);
      }
    },
    [setSalesRegionRepresentatives],
  );

  const loadOperationalArea = useCallback(
    async (operationalAreaCode: number) => {
      setLoadingOperationalAreas(true);

      try {
        const { data: _operationalArea } = await SalesRegionService.getByCode(operationalAreaCode);
        setOperationalArea(_operationalArea);
      } catch (e: any) {
        message.warning('Ocorreu um erro ao buscar os representantes da marca.');
      } finally {
        setLoadingOperationalAreas(false);
      }
    },
    [setOperationalArea, setLoadingOperationalAreas],
  );

  const loadSalesRegionsCustomers = useCallback(
    async (representativeCode: number, options: { cityCode?: number; stateCode?: number; cep?: string }) => {
      setLoadingSalesRegionCustomers(true);

      try {
        const { data: _salesRegionCustomers } = await SalesRegionService.getCustomers({
          page: '0',
          pageSize: '0',
          listAll: true,
          representativeCode,
          salesRegion: { ...options },
        });
        setSalesRegionCustomers(_salesRegionCustomers.data);
      } catch (e: any) {
        message.warning('Ocorreu um erro ao buscar os clientes do representante.');
      } finally {
        setLoadingSalesRegionCustomers(false);
      }
    },
    [setSalesRegionCustomers],
  );
  return (
    <SalesRegionContext.Provider
      value={{
        salesRegionRepresentatives,
        loadingSalesRegionRepresentatives,
        loadSalesRegionsRepresentatives,
        salesRepresentativeFilters,
        salesRepresentativePage,
        salesRepresentativeTotal,

        operationalArea,
        loadingOperationalAreas,
        loadOperationalArea,

        salesRegionCustomers,
        loadingSalesRegionCustomers,
        loadSalesRegionsCustomers,
      }}
    >
      {children}
    </SalesRegionContext.Provider>
  );
};

const useSalesRegion = () => {
  return useContext(SalesRegionContext);
};

export { SalesRegionProvider, useSalesRegion };
