import React, { useEffect } from 'react';
import { Form, Input, Typography, Row, Col, Tag } from 'antd';

import { useProduct } from '@hooks/ProductContext';
import { CustomButton } from '@styles/globals';
import { Reference } from '@models/Reference';
import './styles.css';

const { Text, Title } = Typography;

interface ModalContentReferenceProps {
  reference: Reference;
}

const ModalContentReference: React.FC<ModalContentReferenceProps> = ({reference}) => {
  const {  updateReferenceObservation } = useProduct();

  const [form] = Form.useForm();

  useEffect(() => {
    if (reference) {
      form.setFieldsValue({
        observation: `${reference.observations.map((_observation) => _observation + '\r\n')}`,
      });
    }
  }, [reference]);

  const onEmptyField = (value: string) => {
    if (value) return value;

    return 'Sem informação';
  };

  const onSubmitObservation = async () => {
    const { observation } = form.getFieldsValue();
    await updateReferenceObservation(reference.ReferenceCode, observation.split('\r\n'));
  };

  return (
    <Row className="w-100" align="middle" justify="center">
      <Col
        className="d-flex"
        xs={23}
        sm={23}
        md={22}
        lg={20}
        xl={18}
        xxl={16}
        style={{ background: 'white', flexDirection: 'column', padding: '20px' }}
      >
        <Row gutter={[32, 24]}>
          <Col className="reference-detail-column gutter-row" xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
            <div className="reference-detail-information-container">
              <Title level={5}>Situação</Title>
              {reference.isActive ? (
                <Tag color="green" style={{ height: '24px' }}>
                  Ativo
                </Tag>
              ) : (
                <Tag color="red" style={{ height: '24px' }}>
                  Inativo
                </Tag>
              )}
            </div>

            <div className="reference-detail-information-container">
              <Title level={5}>Descrição</Title>
              <Text className="ml-2">{onEmptyField(reference.name)}</Text>
            </div>

            <div className="reference-detail-information-container">
              <Title level={5}>Peso</Title>
              <Text className="ml-2">{onEmptyField(reference.weight)}</Text>
            </div>

            <div className="reference-detail-information-container">
              <Title level={5}>Altura</Title>
              <Text className="ml-2">{onEmptyField(reference.height)}</Text>
            </div>

            <div className="reference-detail-information-container">
              <Title level={5}>Profundidade</Title>
              <Text className="ml-2">{onEmptyField(reference.width)}</Text>
            </div>

            <div className="reference-detail-information-container">
              <Title level={5}>Peso Embalagem</Title>
              <Text className="ml-2">{onEmptyField(reference.packWeight)}</Text>
            </div>

            <div className="reference-detail-information-container">
              <Title level={5}>Altura Embalagem</Title>
              <Text className="ml-2">{onEmptyField(reference.packHeight)}</Text>
            </div>

            <div className="reference-detail-information-container">
              <Title level={5}>Largura Embalagem</Title>
              <Text className="ml-2">{onEmptyField(reference.packLength)}</Text>
            </div>

            <div className="reference-detail-information-container">
              <Title level={5}>Profundidade da Embalagem</Title>
              <Text className="ml-2">{onEmptyField(reference.packWidth)}</Text>
            </div>
          </Col>

          <Col className="reference-detail-column gutter-row" xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
            <Form form={form} layout="vertical" onFinish={onSubmitObservation}>
              <Form.Item
                label="Observação"
                name="observation"
                rules={[{ required: true, message: 'Por favor informe suas observações' }]}
              >
                <Input.TextArea
                  className="text-area w-100"
                  rows={8}
                  placeholder="Informe suas observações..."
                  autoFocus
                />
              </Form.Item>
              <CustomButton className="w-100 mt-0" type="primary" htmlType="submit">
                Salvar
              </CustomButton>
            </Form>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default ModalContentReference;
