import { Table, Tag } from 'antd';

import LoadingComponents from '@components/LoadingComponents';
import { useOrder } from '@hooks/OrdersContext';
import { Order } from '@models/Order';
import { formatCurrency } from '@utils/currency-format';

interface ItemDetailsProps {
  order: Order;
  isFailedOrder?: boolean;
}

const ItemDetails: React.FC<ItemDetailsProps> = ({ order, isFailedOrder }) => {
  const { loadingOrderDetail } = useOrder();

  if (loadingOrderDetail) return <LoadingComponents />;

  let columns = [];
  columns = [
    {
      title: 'Descrição',
      dataIndex: 'name',
    },
    {
      title: 'Tamanho',
      dataIndex: 'sizeName',
      align: 'center' as 'center',
    },
    {
      title: 'Cor',
      dataIndex: 'colorName',
    },
    {
      title: 'Referência',
      dataIndex: 'referenceCode',
    },
    {
      title: 'Qtde.',
      dataIndex: 'Quantity',
      align: 'right' as 'right',
    },
    {
      title: 'Valor',
      align: 'right' as 'right',
      render: (_item: any) => (
        <Tag color="green" style={{ fontSize: '14px', padding: '2px 4px' }}>
          {formatCurrency(_item.price * ((100 - _item.discountPercentage) / 100) * _item.quantity)}
        </Tag>
      ),
    },
    {
      title: 'Cód. Barras',
      dataIndex: 'productSku',
    },
  ];
  if (isFailedOrder) {
    columns = [
      {
        title: 'Código',
        dataIndex: 'productCode',
        align: 'right' as 'right',
      },
      {
        title: 'Qtde.',
        dataIndex: 'quantity',
        align: 'right' as 'right',
      },
      {
        title: 'Valor',
        align: 'right' as 'right',
        render: (_item: any) => (
          <Tag color="green" style={{ fontSize: '14px', padding: '2px 4px' }}>
            {formatCurrency(_item.price * ((100 - _item.discountPercentage) / 100) * _item.quantity)}
          </Tag>
        ),
      },
    ];
  }
  return (
    <>
      {order?.items ? (
        <Table columns={columns} dataSource={order.items} scroll={{ x: 800 }} pagination={{ pageSize: 5 }} />
      ) : null}
    </>
  );
};

export default ItemDetails;
