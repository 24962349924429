import React, { createContext, useState, ReactNode, useContext, useCallback } from 'react';
import { message } from 'antd';

import { OfficialStore } from '@models/OfficialStore';
import * as OfficialStoreService from '@services/officialStore';
import { OfficialStoreMenu } from '@models/OfficialStoreMenu';
import { Classification } from '@models/Classification';
import { PaymentType } from '@models/PaymentType';
import { OfficialStorePaymentCondition } from '@models/OfficialStorePaymentCondition';
import { OfficialStoreCollection } from '@models/OfficialStoreCollection';
import { INITIAL_PAGE, OFFICIAL_STORE_PAGINATION_SIZE } from '@utils/constants';

interface OfficialStoreState {
  loading: boolean;
  officialStores: OfficialStore[];
  currentPage: number;
  totalPages: number;
  officialStoreFilters: any;

  loadingAddPriceTable: boolean;
  menusOfficialStore: OfficialStoreMenu[];
  actualOfficialStore: OfficialStore;
  loadOfficialStores: Function;
  findOfficialStoreById: Function;
  findOfficialStoreMenusById: Function;
  createOfficialStore: Function;
  createOfficialStoreMenu: Function;
  createOfficialStoreSubMenu: Function;
  addClassificationOfficialStoreMenu: Function;
  addClassificationOfficialStoreSubMenu: Function;
  addPriceTableToOfficialStore: Function;
  updateOfficialStore: Function;
  updateOfficialStoreMenu: Function;
  updateOfficialStoreSubMenu: Function;
  updateOfficialStoreCollections: Function;
  updateOfficialStoreMinimumValue: Function;
  updateOfficialStorePaymentType: Function;
  updateOfficialStorePaymentConditions: Function;
  deleteOfficialStoreMenu: Function;
  deleteOfficialStoreSubMenu: Function;
  deleteOfficialStoreMenuClassification: Function;
  deleteOfficialStoreSubMenuClassification: Function;
  deletePriceTableFromOfficialStore: Function;
}

interface OfficialStoreProviderProps {
  children: ReactNode;
}

export const OfficialStoreContext = createContext<OfficialStoreState>({} as OfficialStoreState);

const OfficialStoreProvider: React.FC<OfficialStoreProviderProps> = ({ children }) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [, setLoadingAddClassification] = useState<boolean>(false);
  const [loadingAddPriceTable, setLoadingAddPriceTable] = useState<boolean>(false);

  const [officialStores, setOfficialStores] = useState<any[]>([]);
  const [officialStoreFilters, setOfficialStoreFilters] = useState<any>({} as any);
  const [currentPage, setCurrentPage] = useState<number>(INITIAL_PAGE);
  const [totalPages, setTotalPages] = useState<number>(0);

  const [menusOfficialStore, setMenusOfficialStores] = useState<any[]>([]);
  const [actualOfficialStore, setActualOfficialStore] = useState<OfficialStore>({} as OfficialStore);

  const loadOfficialStores = async (page: number, searchByName?: string, listAll?: boolean) => {
    setLoading(true);
    setCurrentPage(page);

    if (searchByName) setOfficialStoreFilters({ name: searchByName });
    else setOfficialStoreFilters({});

    let options = {};
    if (listAll) {
      options = { page: 0, pageSize: 0, listAll: true };
    } else if (searchByName) {
      options = { page: 0, pageSize: 0, searchByName, listAll: true };
    } else {
      options = { page, pageSize: OFFICIAL_STORE_PAGINATION_SIZE };
    }
    options = { orderBy: 'official_store_id', direction: 'DESC', ...options };

    try {
      const { data: _officialStores } = await OfficialStoreService.get(options);
      if (_officialStores.total === 0) message.warning('Nenhum registro encontrado!');
      setOfficialStores(_officialStores.data);
      setTotalPages(_officialStores.total);
    } catch (e: any) {
      if (e.response && e.response.status === 404) {
        message.info('Você não possui Lojas Oficiais!');
      }
    } finally {
      setLoading(false);
    }
  };

  const findOfficialStoreById = useCallback(
    async (officialStoreId: number) => {
      setLoading(true);

      const { data: _officialStore } = await OfficialStoreService.getById(officialStoreId);
      setActualOfficialStore(_officialStore);

      setLoading(false);
    },
    [setActualOfficialStore],
  );

  const findOfficialStoreMenusById = useCallback(
    async (officialStoreId: number) => {
      setLoading(true);

      const { data: _menus } = await OfficialStoreService.getMenusById(officialStoreId);
      setMenusOfficialStores(_menus);

      setLoading(false);
    },
    [setMenusOfficialStores],
  );

  const createOfficialStore = useCallback(
    async (newOfficialStore: Partial<OfficialStore>) => {
      setLoading(true);

      const { data } = await OfficialStoreService.create(newOfficialStore);

      if (!data.error) setOfficialStores((state) => [...state, data]);

      setLoading(false);

      return data;
    },
    [officialStores],
  );

  const createOfficialStoreMenu = useCallback(async (officialStoreId: number, description: string) => {
    setLoading(true);

    const { data } = await OfficialStoreService.createMenu(officialStoreId, {
      description: description.toUpperCase(),
    });

    await findOfficialStoreMenusById(officialStoreId);

    setLoading(false);

    return data.error ? false : true;
  }, []);

  const createOfficialStoreSubMenu = useCallback(
    async (officialStoreId: number, menuId: number, description: string) => {
      setLoading(true);

      try {
        await OfficialStoreService.createSubMenu(officialStoreId, menuId, {
          description: description.toUpperCase(),
        });
        await findOfficialStoreMenusById(officialStoreId);
        message.success('SubMenu cadastrado com sucesso!');
      } catch {
        message.error('Erro ao cadastrar SubMenu!');
      } finally {
        setLoading(false);
      }
    },
    [],
  );

  const addClassificationOfficialStoreMenu = useCallback(
    async (officialStoreId: number, menuId: number, classification: Partial<Classification>) => {
      setLoadingAddClassification(true);
      try {
        await OfficialStoreService.addClassificationMenu(officialStoreId, menuId, classification);
        const index = menusOfficialStore.findIndex((_menu) => _menu.menuId == menuId);

        if (index !== -1) {
          menusOfficialStore[index].classifications = [...menusOfficialStore[index].classifications, classification];
          setMenusOfficialStores([...menusOfficialStore]);
        }

        message.success('Classificação cadastrada com sucesso!');
      } catch {
        message.error('Erro ao cadastrar classificação!');
      } finally {
        setLoadingAddClassification(false);
      }
    },
    [setLoadingAddClassification, menusOfficialStore, setMenusOfficialStores],
  );

  const addClassificationOfficialStoreSubMenu = useCallback(
    async (officialStoreId: number, menuId: number, subMenuId: number, classification: Partial<Classification>) => {
      setLoadingAddClassification(true);
      try {
        await OfficialStoreService.addClassificationSubMenu(officialStoreId, menuId, subMenuId, classification);
        const indexMenu = menusOfficialStore.findIndex((_menu) => _menu.menuId == menuId);

        if (indexMenu !== -1) {
          const indexSubMenu = menusOfficialStore[indexMenu].subMenus.findIndex(
            (_submenu: any) => _submenu.subMenuId == subMenuId,
          );
          if (indexSubMenu !== -1) {
            const indexClassification = menusOfficialStore[indexMenu].subMenus[indexSubMenu].classifications.findIndex(
              (_classification: Classification) =>
                _classification.typeCode == classification.typeCode && _classification.code == classification.code,
            );

            if (indexClassification !== -1) return message.error('Classificação já existe');

            menusOfficialStore[indexMenu].subMenus[indexSubMenu].classifications = [
              ...menusOfficialStore[indexMenu].subMenus[indexSubMenu].classifications,
              classification,
            ];
            setMenusOfficialStores([...menusOfficialStore]);
          }
        }

        message.success('Classificação cadastrada com sucesso!');
      } catch {
        message.error('Erro ao cadastrar classificação!');
      } finally {
        setLoadingAddClassification(false);
      }
    },
    [menusOfficialStore, setLoadingAddClassification, setMenusOfficialStores],
  );

  const addPriceTableToOfficialStore = useCallback(
    async (officialStoreId: number, priceTableCode: number, stateCode: string[], applyToAll?: boolean) => {
      setLoadingAddPriceTable(true);
      try {
        if (!applyToAll) {
          await Promise.all(
            stateCode.map((stateUf) => OfficialStoreService.addPriceTable(officialStoreId, priceTableCode, stateUf)),
          );
        } else {
          await OfficialStoreService.addPriceTableApplyAll(officialStoreId, priceTableCode);
        }

        await findOfficialStoreById(officialStoreId);

        message.success('Tabela de Preço adicionada com sucesso!');
      } catch (e) {
        message.error('Erro ao adicionar Tabela de Preço!');
      } finally {
        setLoadingAddPriceTable(false);
      }
    },
    [setLoadingAddPriceTable],
  );

  const updateOfficialStore = useCallback(
    async (officialStoreId: number, officialStoreToUpdate: Partial<OfficialStore>) => {
      setLoading(true);

      const { data } = await OfficialStoreService.update(officialStoreId, officialStoreToUpdate);

      setLoading(false);

      return data.error ? false : true;
    },
    [officialStores],
  );

  const updateOfficialStoreMenu = useCallback(async (officialStoreId: number, menuId: number, description: string) => {
    setLoading(true);

    const { data } = await OfficialStoreService.updateMenu(officialStoreId, menuId, {
      description: description.toUpperCase(),
    });

    await findOfficialStoreMenusById(officialStoreId);

    setLoading(false);

    return data.error ? false : true;
  }, []);

  const updateOfficialStoreSubMenu = useCallback(
    async (officialStoreId: number, menuId: number, subMenuId: number, description: string) => {
      setLoading(true);
      try {
        await OfficialStoreService.updateSubMenu(officialStoreId, menuId, subMenuId, {
          description: description.toUpperCase(),
        });

        await findOfficialStoreMenusById(officialStoreId);
        message.success('SubMenu atualizado com sucesso!');
      } catch {
        message.error('Erro ao atualizar SubMenu!');
      } finally {
        setLoading(false);
      }
    },
    [findOfficialStoreMenusById, setLoading],
  );

  const updateOfficialStoreCollections = useCallback(
    async (officialStoreId: number, collections: Partial<OfficialStoreCollection>[]) => {
      setLoading(true);
      const collectionsToSave = collections.length
        ? collections.map((collection) => collection.collectionId).filter((x) => x)
        : [];

      const { data } = await OfficialStoreService.updateCollections(officialStoreId, collectionsToSave as number[]);

      await findOfficialStoreById(officialStoreId);

      setLoading(false);

      return data.error ? false : true;
    },
    [officialStores],
  );

  const updateOfficialStoreMinimumValue = useCallback(
    async (officialStoreId: number, minimumCifValue: number) => {
      setLoading(true);

      const { data } = await OfficialStoreService.updateMinimumValue(officialStoreId, {
        property: 'minimumCifValue',
        value: minimumCifValue,
      });

      await findOfficialStoreById(officialStoreId);

      setLoading(false);

      return data.error ? false : true;
    },
    [officialStores],
  );

  const updateOfficialStorePaymentType = useCallback(
    async (paymentType: PaymentType) => {
      setLoading(true);

      const { integrationId, officialStoreId, ...paymentTypeToUpdate } = paymentType;

      const { data } = await OfficialStoreService.updatePaymentType(paymentType.officialStoreId, paymentTypeToUpdate);

      await findOfficialStoreById(paymentType.officialStoreId);

      setLoading(false);

      return data.error ? false : true;
    },
    [officialStores],
  );

  const updateOfficialStorePaymentConditions = useCallback(
    async (officialStoreId: number, conditionsToUpdate: Partial<OfficialStorePaymentCondition>[]) => {
      setLoading(true);

      const { data } = await OfficialStoreService.updatePaymentConditions(officialStoreId, conditionsToUpdate);

      await findOfficialStoreById(officialStoreId);

      setLoading(false);

      return data.error ? false : true;
    },
    [officialStores],
  );

  const deleteOfficialStoreMenu = useCallback(async (officialStoreId: number, menuId: number) => {
    setLoading(true);

    const { data } = await OfficialStoreService.deleteMenu(officialStoreId, menuId);

    await findOfficialStoreMenusById(officialStoreId);

    setLoading(false);

    return data.error ? false : true;
  }, []);

  const deleteOfficialStoreSubMenu = useCallback(async (officialStoreId: number, menuId: number, subMenuId: number) => {
    setLoading(true);

    const { data } = await OfficialStoreService.deleteSubMenu(officialStoreId, menuId, subMenuId);

    await findOfficialStoreMenusById(officialStoreId);

    setLoading(false);

    return data.error ? false : true;
  }, []);

  const deleteOfficialStoreMenuClassification = useCallback(
    async (officialStoreId: number, menuId: number, classification: Partial<Classification>) => {
      try {
        await OfficialStoreService.deleteMenuClassification(officialStoreId, menuId, classification);

        const indexMenu = menusOfficialStore.findIndex((_menu) => _menu.menuId == menuId);

        if (indexMenu !== -1) {
          const indexClassification = menusOfficialStore[indexMenu].classifications.findIndex(
            (_classification: Classification) =>
              _classification.typeCode == classification.typeCode && _classification.code == classification.code,
          );

          if (indexClassification !== -1) {
            menusOfficialStore[indexMenu].classifications.splice(indexClassification, 1);
            setMenusOfficialStores([...menusOfficialStore]);
            message.success('Classificação deletada com sucesso');
          }
        }
      } catch {
        message.error('Erro ao deletar classificação!');
      }
    },
    [menusOfficialStore, setMenusOfficialStores],
  );

  const deleteOfficialStoreSubMenuClassification = useCallback(
    async (officialStoreId: number, menuId: number, subMenuId: number, classification: Partial<Classification>) => {
      try {
        await OfficialStoreService.deleteSubMenuClassification(officialStoreId, menuId, subMenuId, classification);

        const indexMenu = menusOfficialStore.findIndex((_menu) => _menu.menuId == menuId);

        if (indexMenu !== -1) {
          const indexSubMenu = menusOfficialStore[indexMenu].subMenus.findIndex(
            (_submenu: any) => _submenu.subMenuId == subMenuId,
          );
          if (indexSubMenu !== -1) {
            const indexClassification = menusOfficialStore[indexMenu].subMenus[indexSubMenu].classifications.findIndex(
              (_classification: Classification) =>
                _classification.typeCode == classification.typeCode && _classification.code == classification.code,
            );

            if (indexClassification !== -1) {
              menusOfficialStore[indexMenu].subMenus[indexSubMenu].classifications.splice(indexClassification, 1);

              menusOfficialStore[indexMenu].subMenus[indexSubMenu].classifications = [
                ...menusOfficialStore[indexMenu].subMenus[indexSubMenu].classifications,
              ];

              setMenusOfficialStores([...menusOfficialStore]);
              message.success('Classificação deletada com sucesso');
            }
          }
        }
      } catch {
        message.error('Erro ao deletar classificação!');
      }
    },
    [menusOfficialStore, setMenusOfficialStores],
  );

  const deletePriceTableFromOfficialStore = useCallback(
    async (officialStoreId: number, priceTableCode: number, stateCode: string) => {
      setLoadingAddPriceTable(true);
      try {
        await OfficialStoreService.deletePriceTable(officialStoreId, priceTableCode, stateCode);

        await findOfficialStoreById(officialStoreId);

        message.success('Tabela de Preço removida com sucesso!');
      } catch (e) {
        message.error('Erro ao remover Tabela de Preço!');
      } finally {
        setLoadingAddPriceTable(false);
      }
    },
    [setLoadingAddPriceTable],
  );
  return (
    <OfficialStoreContext.Provider
      value={{
        loading,
        currentPage,
        totalPages,
        officialStoreFilters,
        loadingAddPriceTable,
        officialStores,
        menusOfficialStore,
        actualOfficialStore,
        loadOfficialStores,
        findOfficialStoreById,
        findOfficialStoreMenusById,
        createOfficialStore,
        createOfficialStoreMenu,
        createOfficialStoreSubMenu,
        addClassificationOfficialStoreMenu,
        addClassificationOfficialStoreSubMenu,
        addPriceTableToOfficialStore,
        updateOfficialStore,
        updateOfficialStoreMenu,
        updateOfficialStoreSubMenu,
        updateOfficialStoreCollections,
        updateOfficialStoreMinimumValue,
        updateOfficialStorePaymentType,
        updateOfficialStorePaymentConditions,
        deleteOfficialStoreMenu,
        deleteOfficialStoreSubMenu,
        deleteOfficialStoreMenuClassification,
        deleteOfficialStoreSubMenuClassification,
        deletePriceTableFromOfficialStore,
      }}
    >
      {children}
    </OfficialStoreContext.Provider>
  );
};

const useOfficialStore = () => {
  return useContext(OfficialStoreContext);
};

export { OfficialStoreProvider, useOfficialStore };
