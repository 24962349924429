import React, { useEffect, useState } from 'react';
import { Col, message, Row, Steps, Typography, Form, Modal } from 'antd';
import moment from 'moment';
import { reject } from 'lodash';

import { CustomCard, CustomCardContainer, Container, CustomInput, CustomRangePicker, StepsContainer } from './styles';
import { CustomButton } from '@styles/globals';

import { useCollection } from '@hooks/CollectionContext';
import { CloseOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { Collection, CollectionDto } from '@models/Collection';

const { Title, Text } = Typography;
const { Step } = Steps;

const steps = [
  {
    title: 'Descrição',
    content: () => null,
  },
  {
    title: 'Disponibilidade',
    content: () => null,
  },
  {
    title: 'Produtos',
    content: () => null,
  },
];

interface CollectionFormProps {
  onCloseModal: Function;
  page?: number;
  editingCollection?: Collection;
}

const initialCollectionData: CollectionDto = {
  description: null,
  dateCostumerStart: null,
  dateCostumerEnd: null,
  dateCostumerPremiumStart: null,
  dateCostumerPremiumEnd: null,
  dateRepresentativeStart: null,
  dateRepresentativeEnd: null,
  dateRepresentativePremiumStart: null,
  dateRepresentativePremiumEnd: null,
  dateSellerStart: null,
  dateSellerEnd: null,
  dateSellerPremiumStart: null,
  dateSellerPremiumEnd: null,
  classifications: [],
};

const CollectionForm: React.FC<CollectionFormProps> = ({ onCloseModal, page, editingCollection }) => {
  const { createCollection, updateCollection } = useCollection();
  const [form] = Form.useForm();

  const [currentFormPage, setCurrentFormPage] = useState(0);
  const [collectionData, setCollectionData] = useState<CollectionDto>(initialCollectionData);
  const [editingCollectionId, setEditingCollectionId] = useState<number | null>(null);

  const [classificationType, setClassificationType] = useState<string>();
  const [classification, setClassification] = useState<string>();

  useEffect(() => {
    page ? setCurrentFormPage(page) : setCurrentFormPage(0);

    if (editingCollection) {
      setEditingCollectionId(editingCollection.collectionId);
      setCollectionData({ ...collectionData, ...editingCollection });

      const {
        dateCostumerStart,
        dateCostumerEnd,
        dateCostumerPremiumStart,
        dateCostumerPremiumEnd,
        dateRepresentativeStart,
        dateRepresentativeEnd,
        dateRepresentativePremiumStart,
        dateRepresentativePremiumEnd,
        dateSellerStart,
        dateSellerEnd,
        dateSellerPremiumStart,
        dateSellerPremiumEnd,
      } = editingCollection;

      form.setFieldsValue({
        dateCostumer: handleDate(dateCostumerStart, dateCostumerEnd),
        dateCostumerPremium: handleDate(dateCostumerPremiumStart, dateCostumerPremiumEnd),
        dateRepresentative: handleDate(dateRepresentativeStart, dateRepresentativeEnd),
        dateRepresentativePremium: handleDate(dateRepresentativePremiumStart, dateRepresentativePremiumEnd),
        dateSeller: handleDate(dateSellerStart, dateSellerEnd),
        dateSellerPremium: handleDate(dateSellerPremiumStart, dateSellerPremiumEnd),
      });
    }
  }, [page]);

  const handleDate = (dateStart: string, dateEnd: string): moment.Moment[] => {
    return dateStart && dateEnd ? [moment(dateStart), moment(dateEnd)] : [];
  };

  const onChangeDescription = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCollectionData({ ...collectionData, description: event.target.value });
  };

  const onChangeDateCostumer = (value: any) => {
    setCollectionData({
      ...collectionData,
      dateCostumerStart: moment(value[0]).format('YYYY-MM-DD HH:mm:ss'),
      dateCostumerEnd: moment(value[1]).format('YYYY-MM-DD HH:mm:ss'),
    });
  };

  const onChangeDateCostumerPremium = (value: any) => {
    setCollectionData({
      ...collectionData,
      dateCostumerPremiumStart: moment(value[0]).format('YYYY-MM-DD HH:mm:ss'),
      dateCostumerPremiumEnd: moment(value[1]).format('YYYY-MM-DD HH:mm:ss'),
    });
  };

  const onChangeDateRepresentative = (value: any) => {
    setCollectionData({
      ...collectionData,
      dateRepresentativeStart: moment(value[0]).format('YYYY-MM-DD HH:mm:ss'),
      dateRepresentativeEnd: moment(value[1]).format('YYYY-MM-DD HH:mm:ss'),
    });
  };

  const onChangeDateRepresentativePremium = (value: any) => {
    setCollectionData({
      ...collectionData,
      dateRepresentativePremiumStart: moment(value[0]).format('YYYY-MM-DD HH:mm:ss'),
      dateRepresentativePremiumEnd: moment(value[1]).format('YYYY-MM-DD HH:mm:ss'),
    });
  };

  const onChangeDateSeller = (value: any) => {
    setCollectionData({
      ...collectionData,
      dateSellerStart: moment(value[0]).format('YYYY-MM-DD HH:mm:ss'),
      dateSellerEnd: moment(value[1]).format('YYYY-MM-DD HH:mm:ss'),
    });
  };

  const onChangeDateSellerPremium = (value: any) => {
    setCollectionData({
      ...collectionData,
      dateSellerPremiumStart: moment(value[0]).format('YYYY-MM-DD HH:mm:ss'),
      dateSellerPremiumEnd: moment(value[1]).format('YYYY-MM-DD HH:mm:ss'),
    });
  };

  const addClassification = () => {
    if (!classification) return message.error('Preencha a classificação!');
    if (!classificationType) return message.error('Preencha o tipo de classificação!');

    if (
      collectionData.classifications?.find(
        (findClassification) =>
          findClassification.typeCode === Number(classificationType) && findClassification.code === classification,
      )
    )
      return message.warning('Filtro já existe!');

    setCollectionData({
      ...collectionData,
      classifications: [
        ...collectionData.classifications,
        { code: classification, typeCode: Number(classificationType) },
      ],
    });
    setClassification(undefined);
    setClassificationType(undefined);
  };

  const removeClassification = (typeCode: number, code: string) => {
    const filteredClassifications = reject(
      collectionData.classifications,
      (_classification) => _classification.typeCode === typeCode && _classification.code === code,
    );

    setCollectionData({
      ...collectionData,
      classifications: filteredClassifications,
    });
  };

  const showModalConfirmation = (typeCode: number, code: string) => {
    Modal.confirm({
      title: 'Deseja realmente excluir?',
      icon: <ExclamationCircleOutlined />,
      content: (
        <>
          <Text>{typeCode}</Text>
          <br />
          <Text>{code}</Text>
        </>
      ),
      okText: 'Excluir',
      okType: 'danger',
      onOk() {
        removeClassification(typeCode, code);
      },
    });
  };

  const onClickSubmitButton = async () => {
    let messageText;

    if (editingCollectionId) {
      messageText = 'atualizada';
      await updateCollection(editingCollectionId, collectionData);
    } else {
      messageText = 'cadastrada';
      await createCollection(collectionData);
    }
    setCollectionData(initialCollectionData);
    onCloseModal();
    message.success(`Coleção ${messageText} com sucesso!`);
  };

  const next = () => {
    if (currentFormPage === 0 && (!collectionData.description || collectionData.description.length === 0))
      return message.warning('Preencha o nome da coleção para prosseguir!');

    setCurrentFormPage(currentFormPage + 1);
  };

  const dateElements = [
    { title: 'Lojista', name: 'dateCostumer', onChangeDate: onChangeDateCostumer },
    { title: 'Representante', name: 'dateRepresentative', onChangeDate: onChangeDateRepresentative },
    { title: 'Vendedor', name: 'dateSeller', onChangeDate: onChangeDateSeller },
    {
      title: 'Lojista Premium',
      name: 'dateCostumerPremium',
      onChangeDate: onChangeDateCostumerPremium,
    },
    {
      title: 'Representante Premium',
      name: 'dateRepresentativePremium',
      onChangeDate: onChangeDateRepresentativePremium,
    },
    { title: 'Vendedor Premium', name: 'dateSellerPremium', onChangeDate: onChangeDateSellerPremium },
  ];

  return (
    <Form form={form} className="d-flex" style={{ flexDirection: 'column', alignItems: 'center' }}>
      <StepsContainer progressDot current={currentFormPage}>
        {steps.map((item) => (
          <Step key={item.title} title={item.title} />
        ))}
      </StepsContainer>

      <div className="steps-content">{steps[currentFormPage].content}</div>
      <div className="steps-action d-flex" style={{ alignItems: 'center', justifyContent: 'center' }}>
        <Container
          style={{
            background: `${currentFormPage === 2 ? 'transparent' : '#fff'}`,
            padding: '20px',
            borderRadius: '8px',
          }}
        >
          {currentFormPage === 0 ? (
            <Row className="d-flex" style={{ justifyContent: 'center', alignItems: 'center' }}>
              <Col span={24}>
                <Title level={5}>Nome da Coleção</Title>
                <CustomInput
                  placeholder="Nome da Coleção"
                  onChange={onChangeDescription}
                  value={collectionData?.description || ''}
                />
              </Col>
            </Row>
          ) : null}

          {currentFormPage === 1 ? (
            <Row
              className="d-flex"
              style={{ justifyContent: 'center', alignItems: 'center', padding: '0 8px' }}
              gutter={[24, 0]}
            >
              {dateElements.map(({ title, name, onChangeDate }) => (
                <Col span={8} key={name}>
                  <Title level={5}>{title}</Title>
                  <Form.Item name={name}>
                    <CustomRangePicker format="DD/MM/YYYY" name={name} onChange={onChangeDate} />
                  </Form.Item>
                </Col>
              ))}
            </Row>
          ) : null}

          {currentFormPage === 2 ? (
            <Row className="d-flex" gutter={16}>
              <Col span={12} className="gutter-row">
                <Row style={{ height: '480px', background: '#fff', padding: '20px', borderRadius: '8px' }}>
                  <CustomCardContainer span={24}>
                    {collectionData.classifications.map(({ typeCode, code }) => (
                      <CustomCard key={`${typeCode}-${code}`}>
                        <Row className="card-row">
                          <Col span={14} style={{ paddingLeft: '8px', paddingTop: '8px' }}>
                            <Title level={5} style={{ height: '20px' }}>
                              Tipo de Classificação
                            </Title>
                            <Text>{typeCode}</Text>
                          </Col>
                          <Col span={10} style={{ paddingLeft: '8px', paddingTop: '8px' }}>
                            <Title level={5} style={{ height: '20px' }}>
                              Classificação
                            </Title>
                            <Text>{code}</Text>
                          </Col>
                          <Col span={24} className="card-col" onClick={() => showModalConfirmation(typeCode, code)}>
                            <CloseOutlined style={{ fontSize: '16px', color: 'var(--primary-color)' }} />
                            <Text style={{ fontSize: '14px', color: 'var(--primary-color)' }}>Excluir Filtro</Text>
                          </Col>
                        </Row>
                      </CustomCard>
                    ))}
                  </CustomCardContainer>
                </Row>
              </Col>
              <Col span={12} className="gutter-row">
                <Row style={{ height: '480px', background: '#fff', padding: '40px 20px', borderRadius: '8px' }}>
                  <Col span={24}>
                    <Title level={5}>Tipo de Classificação</Title>
                    <CustomInput
                      placeholder="Tipo de Classificação"
                      type="number"
                      value={classificationType}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => setClassificationType(e.target.value)}
                    />
                  </Col>
                  <Col span={24}>
                    <Title level={5}>Classificação</Title>
                    <CustomInput
                      placeholder="Classificação"
                      value={classification}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => setClassification(e.target.value)}
                    />
                  </Col>
                  <CustomButton type="primary" onClick={addClassification}>
                    ADICIONAR FILTRO DE PRODUTO
                  </CustomButton>
                  <CustomButton
                    type="primary"
                    outlined="true"
                    style={{ marginTop: '80px' }}
                    onClick={onClickSubmitButton}
                  >
                    CONCLUIR E VER COLEÇÃO
                  </CustomButton>
                </Row>
              </Col>
            </Row>
          ) : null}

          {currentFormPage !== 2 ? (
            <CustomButton type="primary" onClick={next}>
              Continuar
            </CustomButton>
          ) : null}
        </Container>
      </div>
    </Form>
  );
};

export default CollectionForm;
