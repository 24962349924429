import * as api from './api';
import { Response } from '@models/Response';
import { OfficialStore } from '@models/OfficialStore';
import { AxiosResponse } from 'axios';

export function get(options: any): Promise<AxiosResponse<any>> {
  return api.get('official-store', options);
}

export function getById(officialStoreId: number): Promise<any> {
  return api.get(`official-store/${officialStoreId}`).catch((error) => {
    return Promise.resolve(error.response);
  });
}

export function getMenusById(officialStoreId: number): Promise<any> {
  return api.get(`official-store/${officialStoreId}/menu`).catch((error) => {
    return Promise.resolve(error.response);
  });
}

export function create(body: any): Promise<Response<OfficialStore>> | Promise<any> {
  return api.post('official-store', body).catch((error) => {
    return Promise.resolve(error.response);
  });
}

export function createMenu(officialStoreId: number, body: any): Promise<any> {
  return api.post(`official-store/${officialStoreId}/menu`, body).catch((error) => {
    return Promise.resolve(error.response);
  });
}

export function createSubMenu(officialStoreId: number, menuId: number, body: any): Promise<any> {
  return api.post(`official-store/${officialStoreId}/menu/${menuId}`, body).catch((error) => {
    return Promise.resolve(error.response);
  });
}

export function addClassificationMenu(officialStoreId: number, menuId: number, body: any): Promise<any> {
  return api.post(`official-store/${officialStoreId}/menu/${menuId}/classification`, body).catch((error) => {
    return Promise.resolve(error.response);
  });
}

export function addClassificationSubMenu(
  officialStoreId: number,
  menuId: number,
  subMenuId: number,
  body: any,
): Promise<any> {
  return api
    .post(`official-store/${officialStoreId}/menu/${menuId}/submenu/${subMenuId}/classification`, body)
    .catch((error) => {
      return Promise.resolve(error.response);
    });
}

export function addPriceTable(officialStoreId: number, priceTableCode: number, stateCode: string): Promise<any> {
  return api.post(`official-store/${officialStoreId}/price_table`, { priceTableCode, stateCode }).catch((error) => {
    return Promise.resolve(error.response);
  });
}

export function addPriceTableApplyAll(officialStoreId: number, priceTableCode: number): Promise<any> {
  return api
    .post(`official-store/${officialStoreId}/price_table`, { priceTableCode, applyToAll: true })
    .catch((error) => {
      return Promise.resolve(error.response);
    });
}

export function update(officialStoreId: number, body: any): Promise<Response<OfficialStore>> | Promise<any> {
  return api.put(`official-store/${officialStoreId}`, body).catch((error) => {
    return Promise.resolve(error.response);
  });
}

export function updateMenu(officialStoreId: number, menuId: number, body: any): Promise<any> {
  return api.put(`official-store/${officialStoreId}/menu/${menuId}`, body).catch((error) => {
    return Promise.resolve(error.response);
  });
}

export function updateSubMenu(officialStoreId: number, menuId: number, subMenuId: number, body: any): Promise<any> {
  return api.put(`official-store/${officialStoreId}/menu/${menuId}/submenu/${subMenuId}`, body).catch((error) => {
    return Promise.resolve(error.response);
  });
}

export function updateCollections(
  officialStoreId: number,
  collections: number[],
): Promise<Response<OfficialStore>> | Promise<any> {
  return api.put(`official-store/${officialStoreId}/collections`, { collections }).catch((error) => {
    return Promise.resolve(error.response);
  });
}

export function updateMinimumValue(officialStoreId: number, body: any): Promise<any> {
  return api.patch(`official-store/${officialStoreId}`, body).catch((error) => {
    return Promise.resolve(error.response);
  });
}

export function updatePaymentType(officialStoreId: number, body: any): Promise<any> {
  return api.put(`official-store/${officialStoreId}/payment_type`, body).catch((error) => {
    return Promise.resolve(error.response);
  });
}

export function updatePaymentConditions(officialStoreId: number, body: any): Promise<any> {
  return api.put(`official-store/${officialStoreId}/payment_conditions`, body).catch((error) => {
    return Promise.resolve(error.response);
  });
}

export function deleteMenu(officialStoreId: number, menuId: number): Promise<Response<OfficialStore>> | Promise<any> {
  return api.remove(`official-store/${officialStoreId}/menu/${menuId}`).catch((error) => {
    return Promise.resolve(error.response);
  });
}

export function deleteSubMenu(
  officialStoreId: number,
  menuId: number,
  subMenuId: number,
): Promise<Response<OfficialStore>> | Promise<any> {
  return api.remove(`official-store/${officialStoreId}/menu/${menuId}/submenu/${subMenuId}`).catch((error) => {
    return Promise.resolve(error.response);
  });
}

export function deleteMenuClassification(
  officialStoreId: number,
  menuId: number,
  params: any,
): Promise<Response<OfficialStore>> | Promise<any> {
  return api.remove(`official-store/${officialStoreId}/menu/${menuId}/classification`, { params }).catch((error) => {
    return Promise.resolve(error.response);
  });
}

export function deleteSubMenuClassification(
  officialStoreId: number,
  menuId: number,
  subMenuId: number,
  params: any,
): Promise<Response<OfficialStore>> | Promise<any> {
  return api
    .remove(`official-store/${officialStoreId}/menu/${menuId}/submenu/${subMenuId}/classification`, { params })
    .catch((error) => {
      return Promise.resolve(error.response);
    });
}

export function deletePriceTable(officialStoreId: number, priceTableCode: number, stateCode: string): Promise<any> {
  return api.remove(`official-store/${officialStoreId}/price_table/${priceTableCode}/${stateCode}`).catch((error) => {
    return Promise.resolve(error.response);
  });
}

export function uploadImage(file: any): Promise<any> {
  const config = {
    headers: {
      'content-type': 'multipart/form-data',
    },
  };
  const formData = new FormData();
  formData.append('file', file);
  return api.postWithConfig('upload', formData, config).catch((error) => {
    return Promise.resolve(error.response);
  });
}
