import React, { useEffect } from 'react';
import { Switch, BrowserRouter, Route, Redirect } from 'react-router-dom';

import LoginPage from '@pages/LoginPage';
import MainPage from '@pages/MainPage';
import { useKeycloak } from '@react-keycloak/web';
import Loading from '@components/Loading';

const signedRoutes = [
  {
    path: '/painel',
    component: MainPage,
  },
];

const otherRoutes = [
  {
    path: '/login',
    component: LoginPage,
  },
];

const Routes: React.FC = () => {
  const { initialized, keycloak } = useKeycloak();

  if (!initialized) {
    return <Loading />;
  }

  useEffect(() => {
    if (keycloak && initialized) {
      keycloak.onTokenExpired = () => keycloak.updateToken(600);
    }
    return () => {
      if (keycloak) keycloak.onTokenExpired = () => {};
    };
  }, [initialized, keycloak]);

  return (
    <>
      <BrowserRouter>
        <Switch>
          {(keycloak?.authenticated ? signedRoutes : otherRoutes).map(({ component, path }) => {
            return <Route key={component.name} path={path} component={component} />;
          })}
          <Route exact path="*">
            {keycloak?.authenticated ? <Redirect to="/painel" /> : <Redirect to="/login" />}
          </Route>
        </Switch>
      </BrowserRouter>
    </>
  );
};

export default Routes;
