import { Row, Col, Typography } from 'antd';
import { PlusCircleFilled } from '@ant-design/icons';

import * as S from './styles';
import { useState } from 'react';
import { FullScreenModal } from '@styles/globals';
import FormAddPriceTable from '../FormAddPriceTable';

const { Title } = Typography;

const PriceTablesHeader: React.FC = () => {
  const [modalIsVisible, setModalIsVisible] = useState(false);

  const onCloseModal = () => setModalIsVisible(false);
  const onShowModal = () => setModalIsVisible(true);

  return (
    <>
      <Row className="mt-8" justify="space-between">
        <Col>
          <Title level={2}>Tabelas de Preço</Title>
        </Col>
        <Col>
          <S.ActionButtonContainer onClick={onShowModal}>
            <PlusCircleFilled style={{ fontSize: '20px', color: 'var(--primary-color)' }} />
            <S.ActionButtonLabel>Adicionar Tabela de Preço</S.ActionButtonLabel>
          </S.ActionButtonContainer>
        </Col>
      </Row>

      <FullScreenModal
        destroyOnClose={true}
        visible={modalIsVisible}
        onOk={onCloseModal}
        onCancel={onCloseModal}
        footer={null}
        forceRender={false}
      >
        <FormAddPriceTable />
      </FullScreenModal>
    </>
  );
};

export default PriceTablesHeader;
