import React, { useCallback, useEffect, useState } from 'react';

import { Button, Col, Form, Row, Input } from 'antd';
import MaskedInput from 'antd-mask-input';

import './styles.css';
import { Collaborator } from '@models/Collaborator';
import { ICollaboratorForm } from '@pages/CollaboratorsPage/models/ICollaboratorForm';

interface CollaboratorsFormProps {
  onSubmit: Function;
  modal: any;
  collaboratorExists?: Collaborator | null;
  setCurrentCollaborator?: any;
}

const CollaboratorForm: React.FC<CollaboratorsFormProps> = ({ onSubmit, collaboratorExists }) => {
  const [current, setCurrent] = useState(0);
  const [firstName, setFirstName] = useState<string>('');
  const [lastName, setLastName] = useState<string>('');
  const [cpf, setCpf] = useState<string>('');
  const [email, setEmail] = useState<string>('');

  const [form] = Form.useForm();

  const next = () => {
    if (!firstName || !lastName || !cpf || !email) {
      return;
    }

    setCurrent(current + 1);
    next();
  };

  const onFinish = useCallback(() => {
    form
      .validateFields()
      .then((values: ICollaboratorForm) => {
        values.canRead = false;
        values.canWrite = false;
        values.canDelete = false;
        values.collaboratorIsActive = true;
        onSubmit(values);
      })
      .catch((errorInfo) => {
        console.log(errorInfo);
      });
  }, []);

  useEffect(() => {
    if (collaboratorExists) {
      form.setFields([
        { name: 'collaboratorId', value: collaboratorExists?.collaboratorId },
        { name: 'firstName', value: collaboratorExists?.firstName },
        { name: 'lastName', value: collaboratorExists?.lastName },
        { name: 'collaboratorCpf', value: collaboratorExists?.collaboratorCpf },
        { name: 'collaboratorEmail', value: collaboratorExists?.collaboratorEmail },
        { name: 'canRead', value: collaboratorExists?.canRead },
        { name: 'canWrite', value: collaboratorExists?.canWrite },
        { name: 'canDelete', value: collaboratorExists?.canDelete },
      ]);
    } else {
      form.resetFields();
    }
  }, []);

  return (
    <>
      <Row style={{ display: 'flow-root;' }}>
        <Form layout="vertical" form={form}>
          <Row justify="center">
            <Col style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
              <Form.Item name="collaboratorId" hidden={true}>
                <Input type="hidden" />
              </Form.Item>
              <Form.Item
                name="firstName"
                className="mb-2"
                rules={[{ required: true, message: 'Informe o primeiro nome' }]}
              >
                <Input
                  className="collaborator-first-step-inputs"
                  placeholder="Primeiro Nome"
                  onChange={(e) => setFirstName(e.target.value)}
                />
              </Form.Item>

              <Form.Item name="lastName" className="mb-2" rules={[{ required: true, message: 'Informe o sobrenome' }]}>
                <Input
                  className="collaborator-first-step-inputs"
                  placeholder="Sobrenome"
                  onChange={(e) => setLastName(e.target.value)}
                />
              </Form.Item>

              <Form.Item name="collaboratorCpf" className="mb-2" rules={[{ required: true, message: 'Informe o CPF' }]}>
                <MaskedInput
                  mask="11111111111"
                  minLength={11}
                  maxLength={11}
                  className="collaborator-first-step-inputs"
                  placeholder="CPF"
                  onChange={(e) => setCpf(e.target.value)}
                />
              </Form.Item>

              <Form.Item
                name="collaboratorEmail"
                className="mb-2"
                rules={[{ required: true, type: 'email', message: 'Informe um email válido' }]}
              >
                <Input
                  className="collaborator-first-step-inputs"
                  placeholder="E-mail de Acesso"
                  onChange={(e) => setEmail(e.target.value)}
                />
              </Form.Item>
            </Col>
            <Button
              className="mt-4"
              type="primary"
              htmlType="submit"
              onClick={onFinish}
              size="large"
              style={{ width: '-webkit-fill-available', borderRadius: '4px' }}
              block
            >
              <span style={{ color: '#ffffff', fontSize: '12px' }}>SALVAR</span>
            </Button>
          </Row>
        </Form>
      </Row>
    </>
  );
};

export default CollaboratorForm;
