import React, { useCallback, useEffect } from 'react';
import { Checkbox, message, Popconfirm, Table } from 'antd';

import './styles.css';

import { PaymentConditions } from '@models/PaymentConditions';
import { usePaymentConditions } from '@hooks/PaymentConditionsContext';
import Loading from '@components/Loading';

const PaymentPage: React.FC = () => {
  const {
    updatePaymentConditions,
    paymentConditions,
    loadRequestPaymentConditions,
    loadingPaymentConditions,
    paymentConditionFilters,
    paymentConditionTotal,
    paymentConditionsPage,
  } = usePaymentConditions();

  useEffect(() => {
    loadRequestPaymentConditions(0);
  }, []);

  const handleTogglePaymentCondition = useCallback(
    (paymentCondition: PaymentConditions) => {
      paymentCondition.isActive = !paymentCondition.isActive;
      try {
        updatePaymentConditions(
          {
            ...paymentCondition,
          },
          paymentCondition.code,
        );
        message.success('Condição de Pagamento alterada com sucesso!');
      } catch (error) {
        message.error('Falha ao salvar condição de pagamento');
        console.log(error);
      }
    },
    [updatePaymentConditions],
  );

  const paymentColumns = [
    {
      title: 'Ativo',
      dataIndex: 'isActive',
      sorter: true,
      render: (_text: any, paymentCondition: any): JSX.Element => {
        const { key, ...selectedPaymentConditions } = paymentCondition;
        return (
          <>
            <Popconfirm
              title={`Tem certeza que deseja ${
                selectedPaymentConditions.isActive ? 'desativar' : 'ativar'
              } a condição de pagamento ${selectedPaymentConditions.code}?`}
              onConfirm={() => handleTogglePaymentCondition(selectedPaymentConditions)}
              okText="Sim"
              cancelText="Não"
            >
              <Checkbox checked={selectedPaymentConditions.isActive} className="ml-4" />
            </Popconfirm>
          </>
        );
      },
    },
    {
      title: 'Cód.',
      dataIndex: 'code',
      sorter: true,
    },
    {
      title: 'Nome da Condição de Pagamento',
      dataIndex: 'name',
      sorter: true,
    },
    {
      title: 'Parcelamento',
      dataIndex: 'installment',
      sorter: true,
    },
  ];

  const handleTableChange = (pagination: any, filters: any, sorter: any) => {
    if (sorter.hasOwnProperty('column')) {
      paymentConditionFilters.field = `${sorter.field}`;
      paymentConditionFilters.order = `${sorter.order}`;
    }

    loadRequestPaymentConditions(pagination.current, paymentConditionFilters);
  };

  return (
    <>
      <h1 className="payment-table-h1 mt-8">Configurações Gerais de Pagamento</h1>

      <Table
        scroll={{ x: 800 }}
        dataSource={paymentConditions}
        columns={paymentColumns}
        className="payment-table mt-8"
        loading={loadingPaymentConditions}
        pagination={{
          current: paymentConditionsPage,
          total: paymentConditionTotal,
          pageSize: 10,
          showSizeChanger: false,
        }}
        onChange={handleTableChange}
      />
    </>
  );
};

export default PaymentPage;
