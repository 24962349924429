import React from 'react';
import { Table } from 'antd';

import './styles.css';
import { useRepresentative } from '@hooks/RepresentativeContext';

interface SalesRegionsTabProps {}

const SalesRegionsTab: React.FC<SalesRegionsTabProps> = () => {
  const { actualRepresentative } = useRepresentative();

  const columns = [
    {
      dataIndex: 'salesRegionCode',
      render: (text: any) => <strong>{text}</strong>,
      width: '80px',
    },
    {
      dataIndex: 'salesRegionName',
      render: (text: any) => <strong>{text}</strong>,
    },
  ];

  const columnssub = [
    {
      dataIndex: 'cep',
      width: '100px',
    },
    {
      dataIndex: 'addressName',
    },
  ];
  return (
    <Table
      columns={columns}
      dataSource={actualRepresentative.salesRegions}
      showHeader={false}
      rowKey={(record) => record.salesRegionName}
      className="table-antd"
      rowClassName="row-dark"
      scroll={{ x: 800 }}
      expandable={{
        expandedRowRender: () => (
          <Table columns={columnssub} dataSource={[]} showHeader={false} pagination={{ hideOnSinglePage: true }} />
        ),
      }}
    />
  );
};

export default SalesRegionsTab;
