import { Collection } from '@models/Collection';
import { Response } from '@models/Response';
import * as api from './api';

export function get(params?: any) {
  return api.get('collections', params);
}

export async function saveCollection(body: any): Promise<any> {
  return api.post('collections', body);
}

export async function updateCollection(id: number, body: any): Promise<any> {
  return api.put(`collections/${id}`, body);
}
