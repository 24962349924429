import * as api from './api';

export function get(params: any): Promise<any> {
  return api.get('representatives', params);
}

export function getById(representativeId: number): Promise<any> {
  return api.get(`representatives/${representativeId}`);
}

export function getCustomersByRepresentativeId(representativeId: number, params: any): Promise<any> {
  return api.get(`representatives/${representativeId}/customers`, params);
}

export function getOrdersByRepresentativeId(representativeId: number, params: any): Promise<any> {
  return api.get(`representatives/${representativeId}/orders`, params);
}

export function updateAttribute(representativeId: number, params: any): Promise<any> {
  return api.patch(`representatives/${representativeId}`, params);
}

export function uploadImage(file: any): Promise<any> {
  const config = {
    headers: {
      'content-type': 'multipart/form-data',
    },
  };
  const formData = new FormData();
  formData.append('file', file);
  return api.postWithConfig('upload', formData, config).catch((error) => {
    return Promise.resolve(error.response);
  });
}
