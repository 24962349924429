import styled, { createGlobalStyle, css } from 'styled-components';

import 'antd/dist/antd.css';
import 'antd-css-utilities/utility.min.css';

import { Button, Modal } from 'antd';

export const themeColors = {
  primary_color: '#510c76',
  primary_color_hover: '#310966',
  secondary_color: '#eb0045',
  support_color: '#ffffff',
};

export default createGlobalStyle`
  * {
		margin: 0;
		padding: 0;
		outline: 0;
		box-sizing: border-box;
	}

  body {
    overflow: hidden;
  }

  :root {
    --primary-color: ${themeColors.primary_color};
    --secondary-color: ${themeColors.secondary_color};
    --support-color: ${themeColors.support_color};
    --primary-color-hover: ${themeColors.primary_color_hover};
  }

  .background-primary-color {
    background-color: var(--primary-color);
  }


  .background-white {
    background-color: white;
  }

  .overflow-auto {
    overflow: auto;
  }

  .ant-upload-select {
    border-radius: 50% !important;
  }

  .ant-btn-primary,
  .ant-btn-primary:visited,
  .ant-btn-primary::after,
  .ant-btn-primary::before,
  .ant-btn-primary:focus,
  .ant-btn-primary::selection {
    border: var(--primary-color);
    background-color: var(--primary-color);
  }

  .ant-btn-primary:hover{
    background-color: ${themeColors.primary_color_hover};
  }

  .ant-upload.ant-upload-select {
    cursor: pointer;
  }

.ant-table-thead th.ant-table-column-has-sorters:hover {
  background: #858585;
}

 .ant-table-thead th.ant-table-column-sort {
  background: #858585;
}

  .row-dark {
      background-color: #555;
  }

  .row-dark strong {
      color: white;
      font-size: 12px;
  }

  .text-primary {
    color: var(--primary-color);
  }
`;

const outlined_button_css = css`
  background-color: #fff !important;
  border: 2px solid var(--primary-color) !important;
  color: var(--primary-color) !important;
`;

interface CustomButtonProps {
  outlined?: string;
}
export const CustomButton = styled(Button)<CustomButtonProps>`
  width: min(320px, 100%);
  height: 48px;
  border-radius: 8px;
  margin-top: 16px;

  ${(props) => props.outlined === 'true' && outlined_button_css}

  > span {
    color: var(--support_color) !important;
    font-size: 12px !important;
    font-weight: 500;
  }
`;

export const FullScreenModal = styled(Modal)`
  animation: fade 0.3s ease-in-out forwards;
  min-width: 100%;
  min-height: 100vh;
  margin: 0;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  position: fixed;

  > .ant-modal,
  .ant-modal-content {
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f5f5f5 !important;
  }

  .ant-modal-content {
    height: 100vh;
    display: flex;
    flex-direction: column;
  }
`;
