import React from 'react';
import { Button, Input, Row } from 'antd';

interface TableFiltersProps {
  inputPlaceholder: string;
  inputValue: any;
  onChangeInputValue: Function;
  onFilter: Function;
  onClearFilters: Function;
}

const TableFilters: React.FC<TableFiltersProps> = ({
  inputValue,
  onChangeInputValue,
  onFilter,
  onClearFilters,
  inputPlaceholder,
}) => {
  return (
    <Row className="d-flex" align="middle" justify="center" style={{ width: '200px', padding: '20px' }}>
      <Input
        autoFocus
        placeholder={inputPlaceholder}
        value={inputValue}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          onChangeInputValue(e.target.value ? [e.target.value] : [])
        }
        onPressEnter={() => onFilter()}
      />

      <Button className="mt-2 mr-1" onClick={() => onClearFilters()}>
        Limpar
      </Button>

      <Button className="mt-2 ml-1" type="primary" onClick={() => onFilter()}>
        Filtrar
      </Button>
    </Row>
  );
};

export default TableFilters;
