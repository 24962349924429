import React, { useEffect, useState } from 'react';
import { Table } from 'antd';

import { RepresentativeOperationalArea } from '@models/SalesRegionRepresentative';
import OperationalAreasTable from '../OperationalAreasTable';
import { useSalesRegion } from '@hooks/SalesRegionContext';
import '../../styles.css';

interface RepresentativeOperationalAreasTableProps {
  operationalAreas: RepresentativeOperationalArea[];
  selectedRepresentatives: number[];
}
const RepresentativeOperationalAreasTable: React.FC<RepresentativeOperationalAreasTableProps> = ({
  operationalAreas,
  selectedRepresentatives
}) => {
  const [expandedRowKeys, setExpandedRowKeys] = useState<number[]>([]);

  const { loadOperationalArea } = useSalesRegion();

  useEffect(() => {
    setExpandedRowKeys([])
  }, [selectedRepresentatives]);

  const columns = [
    {
      dataIndex: 'salesRegionCode',
      width: '100px',
      align: 'center' as 'center',
    },
    {
      dataIndex: 'salesRegionName',
    },
  ];

  const onTableRowExpand = (expanded: any, record: any) => {
    const keys = [];

    if (expanded) {
      keys.push(record.salesRegionCode);
      loadOperationalArea(record.salesRegionCode);
    }

    setExpandedRowKeys(keys);
  };

  return (
    <Table
      className="mt-2 custom-table"
      columns={columns}
      rowKey={(operationalArea) => operationalArea.salesRegionCode}
      dataSource={operationalAreas}
      expandable={{
        expandedRowRender: (operationalArea) => <OperationalAreasTable representativeCode={operationalArea.representativeCode} />,
        onExpand: onTableRowExpand,
      }}
      expandedRowKeys={expandedRowKeys}
      showHeader={false}
      pagination={false}
      scroll={{ x: 800 }}
    />
  );
};

export default RepresentativeOperationalAreasTable;
