import './styles.css';

import { ExclamationCircleOutlined, FilterFilled, PlusCircleFilled } from '@ant-design/icons';
import CollectionForm from '@components/CollectionForm';
import FiltersList, { ListFilterType } from '@components/FiltersList';
import HeaderButton from '@components/HeaderButton';
import { useCollection } from '@hooks/CollectionContext';
import { Collection } from '@models/Collection';
import { Col, Drawer, Modal, Row, Table, Typography } from 'antd';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useHistory, useRouteMatch } from 'react-router';

import CollectionFilters from './components/CollectionFilters';
import TableExtraInfos from './components/TableExtraInfos';
import { FullScreenModal } from './styles';

const { Title, Text } = Typography;

const CollectionPage: React.FC = () => {
  const history = useHistory();
  const { url } = useRouteMatch();
  const { collectionFilters, filterCollections, collectionCurrentPage, collectionsTotal } = useCollection();

  const columns = [
    {
      title: 'Cód.',
      dataIndex: 'collectionId',
      key: 'collectionId',
      sorter: true,
    },
    {
      title: 'Nome da Coleção',
      dataIndex: 'description',
      key: 'description',
      render: (text: string, collection: Collection): JSX.Element => {
        return (
          <strong
            style={{ cursor: 'pointer' }}
            onClick={() => history.push(`${url}/detalhes/${collection.collectionId}`)}
          >
            {text}
          </strong>
        );
      },
      sorter: true,
    },
    {
      title: 'Data de Criação',
      dataIndex: 'collectionCreatedAt',
      render: (text: string): JSX.Element => <Text>{moment(text).format('DD/MM/YYYY HH:mm:ss')}</Text>,
      sorter: true,
    },
  ];

  const [drawerFiltersVisible, setDrawerFiltersVisible] = useState(false);
  const [modalNewCollectionVisible, setModalNewCollectionVisible] = useState(false);

  const { loadCollections, collections, loading } = useCollection();

  const showFiltersDrawer = () => {
    setDrawerFiltersVisible(true);
  };

  const onCloseFiltersDrawer = () => {
    setDrawerFiltersVisible(false);
  };

  const showNewCollectionModal = () => {
    setModalNewCollectionVisible(true);
  };

  const onCloseNewCollectionModal = () => {
    setModalNewCollectionVisible(false);
  };

  useEffect(() => {
    loadCollections(0);
  }, []);

  const onRemoveFilter = ({ key }: ListFilterType) => {
    Modal.confirm({
      title: 'Deseja realmente excluir?',
      icon: <ExclamationCircleOutlined />,
      okText: 'Excluir',
      okType: 'danger',
      onOk() {
        const newFilters = collectionFilters;
        if (key === 'initialDate' || key === 'finalDate') {
          delete newFilters['initialDate'];
          delete newFilters['finalDate'];
        } else {
          delete newFilters[key];
        }
        filterCollections(newFilters);
      },
    });
  };

  const handleTableChanges = (pagination: any, filters: any, sorter: any) => {
    if (sorter.hasOwnProperty('column')) {
      collectionFilters.field = `${sorter.field}`;
      collectionFilters.order = `${sorter.order}`;
    }

    loadCollections(pagination.current, collectionFilters);
  };

  return (
    <>
      <Row className="mt-8" justify="space-between">
        <Col>
          <Title level={2}>Minhas Coleções</Title>
        </Col>
        <Col>
          <Row>
            <Col>
              <FiltersList
                columns={[
                  { title: 'Descrição', key: 'description' },
                  { title: 'Data Inicial', key: 'initialDate', isDate: true },
                  { title: 'Data Final', key: 'finalDate', isDate: true },
                ]}
                dataSource={collectionFilters}
                onRemove={onRemoveFilter}
              />
            </Col>
            <Col>
              <HeaderButton
                text="Filtros"
                onClick={showFiltersDrawer}
                Icon={<FilterFilled style={{ fontSize: '20px', color: 'var(--primary-color)' }} />}
              />
            </Col>
            <Col>
              <HeaderButton
                text="Nova Coleção"
                onClick={showNewCollectionModal}
                Icon={<PlusCircleFilled style={{ fontSize: '20px', color: 'var(--primary-color)' }} />}
              />
            </Col>
          </Row>
        </Col>
      </Row>

      <Drawer
        title="Filtros"
        placement="left"
        onClose={onCloseFiltersDrawer}
        visible={drawerFiltersVisible}
        width={400}
      >
        <CollectionFilters onCloseDrawer={onCloseFiltersDrawer} />
      </Drawer>

      <FullScreenModal
        destroyOnClose={true}
        visible={modalNewCollectionVisible}
        onOk={onCloseNewCollectionModal}
        onCancel={onCloseNewCollectionModal}
        footer={null}
        forceRender={false}
      >
        <Row align="middle" justify="center" style={{ height: '100%' }}>
          <Col
            span={24}
            className="d-flex"
            style={{ height: '100%', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}
          >
            <CollectionForm onCloseModal={onCloseNewCollectionModal} />
          </Col>
        </Row>
      </FullScreenModal>

      <Row align="middle" justify="center">
        <Col span={24}>
          <Table
            columns={columns as any}
            dataSource={collections}
            expandable={{
              expandedRowRender: (record) => <TableExtraInfos collection={record} />,
            }}
            loading={loading}
            rowKey={(record) => record.collectionId}
            style={{ marginTop: '16px' }}
            scroll={{ x: 800 }}
            pagination={{
              total: collectionsTotal,
              current: collectionCurrentPage,
              pageSize: 10,
              showSizeChanger: false,
            }}
            onChange={handleTableChanges}
          />
        </Col>
      </Row>
    </>
  );
};

export default CollectionPage;
