import React from 'react';
import { Row } from 'antd';

import VisibilityPanel from '../VisibilityPanel';
import SalesTypePanel from '../SalesTypePanel';
import InformationsPanel from '../InformationsPanel';

import { OfficialStore } from '@models/OfficialStore';
import CollectionsPanel from '../CollectionsPanel';
import PriceTablesPanel from '../PriceTablesPanel';

interface OfficialStoreProfileContentProps {
  officialStore: OfficialStore;
  logoUrl: string;
  setLogoUrl: React.Dispatch<React.SetStateAction<string>>;
}

const OfficialStoreProfileContent: React.FC<OfficialStoreProfileContentProps> = ({
  officialStore,
  logoUrl,
  setLogoUrl,
}) => {
  return (
    <Row className="mt-6" gutter={[16, 16]}>
      <InformationsPanel officialStore={officialStore} logoUrl={logoUrl} setLogoUrl={setLogoUrl} />

      <SalesTypePanel />

      <VisibilityPanel />

      <CollectionsPanel
        officialStoreCollections={officialStore.collections}
        officialStoreId={officialStore.officialStoreId}
      />

      <PriceTablesPanel />
    </Row>
  );
};

export default OfficialStoreProfileContent;
