import { message } from 'antd';
import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';

interface Response {
  data: {
    user: {
      name: string;
      email: string;
    };
    token: string;
  };
}

const baseUrl: string | undefined = process.env.REACT_APP_BASE_URL;

axios.interceptors.request.use(
  (request: AxiosRequestConfig) => {
    request.headers['Authorization'] = `Bearer ${localStorage.getItem('token')}`;
    return request;
  },
  (error) => {
    console.log(error);
    return Promise.reject(error);
  },
);

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (!error.response)
      message.error({ content: 'O servidor encontra-se Offline...', duration: 20, key: 'serverOffline' });

    if (error.response.status == 401) window.location.reload();

    return Promise.reject(error);
  },
);

export function get(path: string, params?: any): Promise<AxiosResponse<any>> {
  return axios.get(`${baseUrl}/${path}`, { params });
}

export function post(path: string, body: any, params?: any): Promise<AxiosResponse<any>> {
  return axios.post(`${baseUrl}/${path}`, body, params);
}

export function postWithConfig(path: string, body: any, config: any): Promise<any> {
  return axios.post(`${baseUrl}/${path}`, body, config);
}

export function put(path: string, body: any): Promise<AxiosResponse<any>> {
  return axios.put(`${baseUrl}/${path}`, body);
}

export function patch(path: string, body: any): Promise<AxiosResponse<any>> {
  return axios.patch(`${baseUrl}/${path}`, body);
}

export function remove(path: string, query?: any): Promise<AxiosResponse<any>> {
  return axios.delete(`${baseUrl}/${path}`, query);
}

export function downloadFile(path: string) {
  return axios({
    url: `${baseUrl}/${path}`,
    method: 'GET',
    responseType: 'blob',
  });
}

export function responseIsSuccess(status: number) {
  return status > 200 && status < 300;
}

export const defaults = {
  headers: {
    Authorization: '',
  },
};
